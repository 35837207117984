import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceBanner() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'banner-design-1.webp', alt: 'Image 1' },
        { src: 'banner-design-2.webp', alt: 'Image 2' },
        { src: 'banner-design-3.webp', alt: 'Image 3' },
        { src: 'banner-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Banner Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating eye-catching and effective banners that
                        grab attention, convey key messages, and promote brand awareness. Our banner designs are
                        tailored to meet your marketing objectives and engage your target audience:
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Strategic Consultation",
        "description": "We begin with a strategic consultation to understand your campaign goals, target audience demographics, and messaging priorities. This insight guides our design approach to ensure banners effectively communicate your brand's value proposition."
    },
    {
        "title": "Customized Design Concepts",
        "description": "Our skilled designers develop customized banner designs that align with your brand identity and campaign theme. Whether for digital advertising, events, trade shows, or website headers, each banner is crafted to stand out and drive engagement."
    },
    {
        "title": "Creative Visual Elements",
        "description": "We leverage compelling visuals, graphics, and typography to create visually appealing banners. Clear and concise messaging is incorporated to capture attention and deliver your brand message effectively."
    },
    {
        "title": "Multiple Size and Format Options",
        "description": "Our banner designs are versatile and optimized for various platforms and sizes. Whether you need banners for social media ads, website sliders, email newsletters, or print materials, we provide designs that maintain quality and impact across different mediums."
    },
    {
        "title": "Platform-Specific Optimization",
        "description": "We ensure banners are optimized for specific platforms and placements. This includes adhering to size guidelines, resolution requirements, and content specifications to maximize visibility and engagement."
    },
    {
        "title": "Call-to-Action (CTA) Integration",
        "description": "Each banner includes strategic placement of clear and compelling calls-to-action (CTAs). Whether prompting users to visit your website, learn more about a promotion, or make a purchase, CTAs are designed to drive conversions and user interaction."
    },
    {
        "title": "Brand Consistency",
        "description": "We maintain consistency with your brand's visual identity across all banner designs. This includes using consistent color schemes, fonts, and imagery that reinforce brand recognition and enhance brand recall."
    },
    {
        "title": "Quality Printing and Delivery",
        "description": "For printed banners, we ensure high-quality printing materials and finishes that reflect professionalism and durability. Digital banners are delivered in formats compatible with your preferred advertising platforms or digital signage systems."
    },
    {
        "title": "Feedback and Revisions",
        "description": "We value client feedback throughout the design process and offer revisions to refine banners until they meet your satisfaction. This collaborative approach ensures the final designs align with your expectations and campaign objectives."
    },
    {
        "title": "Timely Execution",
        "description": "We prioritize timely delivery of banner designs to meet campaign launch deadlines. Our efficient workflow and proactive communication ensure smooth project management and client satisfaction."
    }
]
