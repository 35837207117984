import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const KeyFeatures = ({ type }) => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '4% 8%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: isSmallScreen ? 'center' : 'left',
            color: darkMode ? '#fff' : '#000',
        },
        videoContainer: {
            flex: 1,
            marginRight: isSmallScreen ? '0' : '20px',
            marginBottom: isSmallScreen ? '20px' : '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        videoImage: {
            width: '100%',
            borderRadius: '10px',
        },
        playButton: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60px',
            height: '60px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        contentContainer: {
            flex: 1,
            width: '100%',
            paddingLeft: isSmallScreen ? '0' : '20px',
        },
        heading: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign:  'left',
            marginBottom: '20px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            marginBottom: '20px',
        },
        featureList: {
            listStyleType: 'none',
            padding: 0,
        },
        featureItem: {
            display: 'flex',
            alignItems: 'center',
            margin: '20px 0',
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
        },
        featureIcon: {
            color: '#4caf50',
            marginRight: '10px',
        },
    };

    const brandingFeatures = [
        'Expertise and Experience',
        'Targeted Brand Strategy',
        'Innovative Approach',
        'Emotional Connection',
    ];

    const technicalFeatures = [
        'Business-Centric Solutions',
        'Customized Development',
        'Leading Technology',
        'Efficiency and Innovation',
    ];

    return (
        <div style={styles.container}>
            <div style={styles.videoContainer}>
                <img src="/images/laptop-image.png" alt="Video Thumbnail" style={styles.videoImage} />
            </div>
            <div style={styles.contentContainer}>
                <h2 style={styles.heading}>Key Features</h2>
                {/* <p style={styles.description}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy.
                </p> */}
                <div style={styles.featureList}>
                    {
                        type === 'branding' ?
                            <>
                                {brandingFeatures?.map((feature, index) => (
                                    <li key={index} style={styles.featureItem}>
                                        <img src="/images/check-icon.svg" style={{ marginRight: '2%' }} />
                                        {feature}
                                    </li>
                                ))}
                            </>
                            :
                            <>
                                {technicalFeatures?.map((feature, index) => (
                                    <li key={index} style={styles.featureItem}>
                                        <img src="/images/check-icon.svg" style={{ marginRight: '2%' }} />
                                        {feature}
                                    </li>
                                ))}
                            </>
                    }

                </div>
            </div>
        </div>
    );
};

export default KeyFeatures;