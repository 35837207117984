import React, { useState, useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import Heading from './Heading';
import { primaryDarkColor } from '../../constant';

const Pricing = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const isMobileOrTablet = isSmallScreen || isMediumScreen;
    const [activeTab, setActiveTab] = useState(0);

    const styles = {
        container: {
            padding: isMobileOrTablet ? '50px 20px 5px' : '3% 6%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            textAlign: 'center',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '40px',
        },
        pricingTable: {
            display: isMobileOrTablet ? 'block' : 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '20px',
            marginBottom: '40px',
            textAlign: 'center',
        },
        pricingCard: {
            border: darkMode ? '1px solid #555' : '1px solid #ddd',
            borderRadius: '10px',
            padding: '20px',
            textAlign: 'center',
            cursor: isMobileOrTablet ? 'pointer' : 'default',
            backgroundColor: darkMode ? '#282828' : '#fff',
        },
        emptyCell: {
            padding: '20px',
        },
        pricingHeader: {
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '10px',
        },
        pricingPrice: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        pricingButton: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            fontSize: '16px',
            border: 'none',
            cursor: 'pointer',
        },
        featuresTable: {
            width: '100%',
            borderCollapse: 'collapse',
            display: isMobileOrTablet ? 'block' : 'table',
            marginTop: '20px',
        },
        featuresHeader: {
            backgroundColor: darkMode ? '#333' : '#f8f8f8',
            fontWeight: 600,
        },
        featureRow: {
            borderBottom: darkMode ? '1px solid #555' : '1px solid #ddd',
        },
        featureCell: {
            padding: '20px 10px',
            textAlign: 'left',
            fontSize: 14,
            fontWeight: 500,
        },
        checkIcon: {
            width: '20px',
            height: '20px',
        },
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            border: darkMode ? '1px solid #555' : '1px solid #ddd',
            borderRadius: '10px',
            marginBottom: '20px',
            backgroundColor: darkMode ? '#282828' : '#fff',
        },
        tabHeader: {
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '10px',
        },
        tabPrice: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
    };

    const pricingData = [
        {
            title: 'Basic Logo Package',
            price: 'Rs. 999/-',
            features: ['01', '02', '/images/check-icon.svg', 'Basic', '/images/check-icon.svg', '-', '-', '-', '-', '1-2 Days'],
        },
        {
            title: 'Standard Logo Package',
            price: 'Rs. 1499/-',
            features: ['01', '02', '/images/check-icon.svg', 'Basic', '/images/check-icon.svg', '/images/check-icon.svg', '-', '-', '-', '1-2 Days'],
        },
        {
            title: 'Premium Logo Package',
            price: 'Rs. 1999/-',
            features: ['03', '08', '/images/check-icon.svg', 'Premium', '/images/check-icon.svg', '/images/check-icon.svg', '/images/check-icon.svg', '-', '-', '1-2 Days'],
        },
        {
            title: 'Startup Kit Logo Package',
            price: 'Rs. 4999/-',
            features: ['05', 'Unlimited', '/images/check-icon.svg', 'VIP', '/images/check-icon.svg', '/images/check-icon.svg', '/images/check-icon.svg', '/images/check-icon.svg', '/images/check-icon.svg', '2-3 Days'],
        },
    ];

    const featuresHeader = [
        'Logo Concepts',
        'Revision Rounds',
        '3d Mockups',
        'Support',
        'High Resolution Images (JPG & PNG)',
        'Printable Files (AI, EPS, PDF, CDR)',
        'Copyright-Free Design',
        'Business Card',
        'Letterhead & Envelope',
        'Delivery Time',
    ];

    return (
        <div style={styles.container}>
            <Heading title={'Our Pricing'} />
            {isMobileOrTablet ? (
                <div>
                    <div style={styles.pricingTable}>
                        {pricingData.map((plan, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.pricingCard,
                                    backgroundColor: activeTab === index ? (darkMode ? '#383838' : '#f0f0f0') : (darkMode ? '#282828' : '#fff'),
                                }}
                                onClick={() => setActiveTab(index)}
                            >
                                <h3 style={styles.pricingHeader}>{plan.title}</h3>
                                <p style={styles.pricingPrice}>{plan.price}</p>
                            </div>
                        ))}
                    </div>
                    <div style={styles.tabContent}>
                        <h3 style={styles.tabHeader}>{pricingData[activeTab].title}</h3>
                        <p style={styles.tabPrice}>{pricingData[activeTab].price}</p>
                        <button style={styles.pricingButton}>Get started</button>
                        <table style={styles.featuresTable}>
                            <thead>
                                <tr style={styles.featuresHeader}>
                                    <th style={styles.featureCell}>Features</th>
                                    <th style={styles.featureCell}>{pricingData[activeTab].title}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {featuresHeader.map((feature, rowIndex) => (
                                    <tr key={rowIndex} style={styles.featureRow}>
                                        <td style={styles.featureCell}>{feature}</td>
                                        <td style={styles.featureCell}>
                                            {pricingData[activeTab].features[rowIndex] === '-' ? '-' : (
                                                pricingData[activeTab].features[rowIndex].includes('check-icon.svg') ? (
                                                    <img src={pricingData[activeTab].features[rowIndex]} alt="Check Icon" style={styles.checkIcon} />
                                                ) : (
                                                    pricingData[activeTab].features[rowIndex]
                                                )
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                    <div style={styles.pricingTable}>
                        <div style={styles.emptyCell}></div>
                        {pricingData.map((plan, index) => (
                            <div key={index} style={styles.pricingCard}>
                                <h3 style={styles.pricingHeader}>{plan.title}</h3>
                                <p style={styles.pricingPrice}>{plan.price}</p>
                                <button style={styles.pricingButton}>Get started</button>
                            </div>
                        ))}
                    </div>
                    <table style={styles.featuresTable}>
                        <thead>
                            <tr style={styles.featuresHeader}>
                                <th style={styles.featureCell}>Features</th>
                                {pricingData.map((plan, index) => (
                                    <th key={index} style={styles.featureCell}>{plan.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {featuresHeader.map((feature, rowIndex) => (
                                <tr key={rowIndex} style={styles.featureRow}>
                                    <td style={styles.featureCell}>{feature}</td>
                                    {pricingData.map((plan, colIndex) => (
                                        <td key={colIndex} style={styles.featureCell}>
                                            {plan.features[rowIndex] === '-' ? '-' : (
                                                plan.features[rowIndex].includes('check-icon.svg') ? (
                                                    <img src={plan.features[rowIndex]} alt="Check Icon" style={styles.checkIcon} />
                                                ) : (
                                                    plan.features[rowIndex]
                                                )
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Pricing;