import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

export default function ServiceWhiteLabel() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        White-Labeled Website Development Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating white-labeled websites that allow
                        businesses to resell or rebrand under their own brand identity. Our white-labeled website
                        solutions are designed to empower agencies, freelancers, and businesses looking to offer
                        professional web solutions without the need for in-house development.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Custom White-Label Solutions",
        "description": "We provide fully customizable white-labeled websites that can be tailored to match your client’s branding requirements. Whether you need a basic informational site or a complex web application, we ensure flexibility and scalability."
    },
    {
        "title": "Brand Integration",
        "description": "Our team seamlessly integrates your client's brand elements, including logos, color schemes, and typography, into the white-labeled website design. This ensures a cohesive and branded online presence that reflects your client's identity."
    },
    {
        "title": "Scalable Architecture",
        "description": "We leverage scalable architecture and modern web technologies to develop robust white-labeled websites. Our solutions accommodate growth and can handle increased traffic and content management needs over time."
    },
    {
        "title": "Responsive Design",
        "description": "Every white-labeled website we create is built with responsive design principles, ensuring a consistent user experience across desktops, tablets, and smartphones. This enhances accessibility and usability for all visitors."
    },
    {
        "title": "Content Management System (CMS)",
        "description": "We offer integration with user-friendly CMS platforms like WordPress, Drupal, or custom solutions, allowing your clients to manage and update their content effortlessly. This empowers them to maintain a dynamic online presence."
    },
    {
        "title": "E-commerce Functionality",
        "description": "If your clients require e-commerce capabilities, we can integrate secure and scalable online store features. This includes product catalog management, payment gateway integration, and order processing functionalities."
    },
    {
        "title": "SEO Optimization",
        "description": "Our white-labeled websites are optimized for search engines, incorporating SEO best practices such as keyword optimization, meta tags, and site structure. This helps improve visibility and attract organic traffic to your clients' websites."
    },
    {
        "title": "Performance and Security",
        "description": "We prioritize performance optimization and security measures to ensure reliable and secure white-labeled websites. Regular updates, security patches, and monitoring protocols are implemented to safeguard against potential threats."
    },
    {
        "title": "Client Support and Training",
        "description": "We provide ongoing support and training to your clients, empowering them to effectively manage their white-labeled websites. Our support team is available to address technical issues and provide guidance as needed."
    },
    {
        "title": "Client-Centric Approach",
        "description": "We collaborate closely with you and your clients throughout the development process, ensuring clear communication and alignment with project goals. Our goal is to deliver white-labeled websites that meet your clients' expectations and drive business growth."
    }
]
