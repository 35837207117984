import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServicePackaging() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'box-design-1.webp', alt: 'Image 1' },
        { src: 'box-design-2.webp', alt: 'Image 2' },
        { src: 'box-design-3.webp', alt: 'Image 3' },
        { src: 'box-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Packaging Design and Box Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating impactful and innovative packaging
                        designs that enhance product appeal, engage consumers, and reinforce brand identity.
                        Whether you're launching a new product or rebranding an existing one, our design services
                        are tailored to meet your specific packaging needs.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Strategic Consultation",
        "description": "We start with a strategic consultation to understand your product, target market, brand positioning, and packaging requirements. This insight guides our design approach to ensure packaging aligns with your marketing goals."
    },
    {
        "title": "Customized Design Concepts",
        "description": "Our expert designers develop customized packaging and box designs that not only protect your product but also enhance its visual appeal. From structural design to graphic elements, each concept is crafted to stand out on store shelves and online platforms."
    },
    {
        "title": "Creative Visual Elements",
        "description": "We integrate creative visual elements, including colors, typography, imagery, and brand logos, to create designs that resonate with your target audience. Eye-catching graphics and intuitive layout ensure your packaging captures attention and communicates value."
    },
    {
        "title": "Product-Specific Considerations",
        "description": "Whether designing for retail products, subscription boxes, or special promotions, we consider product-specific requirements such as size, shape, weight, and functionality. Our designs are optimized to complement the product and enhance user experience."
    },
    {
        "title": "Brand Consistency",
        "description": "Maintaining consistency with your brand's visual identity is crucial. We ensure that packaging designs reflect your brand's personality, values, and positioning to strengthen brand recognition and customer loyalty."
    },
    {
        "title": "Material Selection and Sustainability",
        "description": "We prioritize environmentally friendly and sustainable packaging materials whenever possible. Our designers recommend suitable materials that align with your brand ethos while meeting functional and aesthetic requirements."
    },
    {
        "title": "Prototyping and Testing",
        "description": "Before finalizing designs, we provide prototyping and testing services to validate packaging concepts. This ensures functionality, durability, and alignment with manufacturing processes to meet industry standards and regulations."
    },
    {
        "title": "Printing and Production Coordination",
        "description": "We collaborate with trusted printing and production partners to ensure high-quality manufacturing of your packaging. From selecting printing techniques to overseeing production, we manage the process to deliver superior results."
    },
    {
        "title": "Digital and Physical Distribution",
        "description": "Whether you require digital renderings for online marketing or physical samples for presentations, we provide files and prototypes suitable for both digital and physical distribution channels."
    },
    {
        "title": "Client Collaboration and Satisfaction",
        "description": "We value client collaboration throughout the design process, from initial concepts to final delivery. Your feedback and input are essential in refining designs to meet your expectations and achieve packaging excellence."
    }
]
