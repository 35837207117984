import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

export default function ServiceSoftware() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Software Development Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in delivering robust and scalable software solutions
                        tailored to meet the unique needs of businesses across various industries. Our software
                        development services are designed to streamline operations, enhance productivity, and drive
                        digital transformation.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Custom Software Development",
        "description": "We offer custom software development services to build tailored solutions that address your specific business challenges and objectives. Whether you need a standalone application or a comprehensive enterprise solution, our team is equipped to deliver."
    },
    {
        "title": "Full-Cycle Development",
        "description": "Our development process encompasses the entire software development lifecycle (SDLC), from initial concept and requirements gathering to design, development, testing, deployment, and ongoing support. We ensure transparency and collaboration at every stage to deliver high-quality software on time and within budget."
    },
    {
        "title": "Technological Expertise",
        "description": "With a team of skilled developers proficient in a wide range of technologies and programming languages (such as Java, Python, .NET, JavaScript, etc.), we leverage the latest tools and frameworks to develop cutting-edge solutions that align with industry best practices."
    },
    {
        "title": "Enterprise Application Development",
        "description": "We specialize in building scalable enterprise applications that integrate seamlessly with your existing systems and processes. Our solutions help streamline workflows, improve efficiency, and facilitate data-driven decision making within your organization."
    },
    {
        "title": "Mobile App Integration",
        "description": "If your business requires mobile capabilities, we can integrate your software solution with mobile applications (iOS, Android) to extend functionality and provide users with access on the go."
    },
    {
        "title": "Cloud Solutions",
        "description": "We leverage cloud technologies (AWS, Azure, Google Cloud) to develop scalable and secure cloud-native applications. Cloud solutions offer flexibility, scalability, and cost-efficiency, enabling your business to adapt and grow in today's dynamic market."
    },
    {
        "title": "Quality Assurance and Testing",
        "description": "Our dedicated QA team conducts rigorous testing throughout the development process to ensure software reliability, performance optimization, and security. We employ automated testing tools and methodologies to deliver bug-free solutions."
    },
    {
        "title": "Maintenance and Support",
        "description": "Beyond deployment, we provide ongoing maintenance and support services to keep your software solution updated, secure, and optimized. Our support team is available to address any issues promptly and ensure uninterrupted system performance."
    },
    {
        "title": "Client-Centric Approach",
        "description": "We prioritize clear communication, transparency, and collaboration with our clients. Your satisfaction is our top priority, and we work closely with you to understand your requirements and deliver solutions that exceed expectations."
    }
]
