import React, { useContext, useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { FiUser } from "react-icons/fi";
import { MdOutlineDesignServices } from "react-icons/md";
import { GoRocket } from "react-icons/go";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaPaintBrush } from "react-icons/fa";
import { FiDatabase } from "react-icons/fi";
import { MdWeb } from "react-icons/md";
import { TbBrandAndroid } from "react-icons/tb";
import { FaApple } from "react-icons/fa";
import { GoBug } from "react-icons/go";
import { MdDeveloperMode } from "react-icons/md";
import { MdOutlineContactSupport } from "react-icons/md";
import { SiMake } from "react-icons/si";
import { primaryDarkColor } from '../../constant';

const Process = () => {
    const { darkMode } = useContext(DarkModeContext);
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            textAlign: 'center',
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            position: 'relative',
            opacity: 0,
            transform: 'translateY(20px) scale(0.9)',
            transition: 'opacity 1.2s ease-out, transform 1.2s ease-out',
        },
        visible: {
            opacity: 1,
            transform: 'translateY(0) scale(1)',
        },
        heading: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '40px',
        },
        processContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '20px',
            marginTop: '5%',
        },
        processItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
            flexBasis: isTabletOrMobile || isSmallScreen ? 'calc(33.33% - 40px)' : '', // Adjust to fit 3 items per row
            maxWidth: isTabletOrMobile || isSmallScreen ? 'calc(33.33% - 40px)' : '',
            opacity: 0,
            transform: 'translateY(20px) scale(0.9)',
            transition: 'opacity 1.2s ease-out, transform 1.2s ease-out',
        },
        itemVisible: {
            opacity: 1,
            transform: 'translateY(0) scale(1)',
        },
        icon: {
            width: '36px',
            height: '36px',
            marginBottom: '10px',
        },
        label: {
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: '10px',
        },
        arrow: {
            width: '24px',
            height: '24px',
            margin: '0 10px',
        },
        developmentStages: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            backgroundColor: darkMode ? '#444' : '#f5f5f5',
            gap: isTabletOrMobile ? '20px' : '150px',
            flexWrap: 'wrap',
            margin: isTabletOrMobile ? '' : '3% 10%',
        },
        stageItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '10px',
            flexBasis: isTabletOrMobile || isSmallScreen ? 'calc(33.33% - 40px)' : '', // Adjust to fit 3 items per row
            maxWidth: isTabletOrMobile || isSmallScreen ? 'calc(33.33% - 40px)' : '',
            opacity: 0,
            transform: 'translateY(20px) scale(0.9)',
            transition: 'opacity 1.2s ease-out, transform 1.2s ease-out',
        },
        stageVisible: {
            opacity: 1,
            transform: 'translateY(0) scale(1)',
        },
        processArrow: {
            position: 'absolute'
        }
    };

    return (
        <div ref={sectionRef} style={{ ...styles.container, ...(isVisible && styles.visible) }}>
            {darkMode ? (
                <>
                    <img src='/images/right-process-arrow-white.svg' style={{ ...styles.processArrow, right: '5%', top: '30%' }} />
                    <img src='/images/left-process-arrow-white.svg' style={{ ...styles.processArrow, left: '5%', top: '60%' }} />
                </>
            ) : (
                <>
                    <img src='/images/right-process-arrow.svg' style={{ ...styles.processArrow, right: '5%', top: '30%' }} />
                    <img src='/images/left-process-arrow.svg' style={{ ...styles.processArrow, left: '5%', top: '60%' }} />
                </>
            )}
            <Heading title={'Our Process'} />
            <div style={styles.processContainer}>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <FiUser style={styles.icon} />
                    <span style={styles.label}>Client Enquiry</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <MdOutlineDesignServices style={styles.icon} />
                    <span style={styles.label}>Proposal Signing</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <GoRocket style={styles.icon} />
                    <span style={styles.label}>Project Kickoff</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <HiOutlineLightBulb style={styles.icon} />
                    <span style={styles.label}>Ideation & Evaluation</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <FaPaintBrush style={styles.icon} />
                    <span style={styles.label}>Design</span>
                </div>
            </div>
            <div style={styles.developmentStages}>
                <div style={{ ...styles.stageItem, ...(isVisible && styles.stageVisible) }}>
                    <FiDatabase style={styles.icon} />
                    <span style={styles.label}>Database Development</span>
                </div>
                <div style={{ ...styles.stageItem, ...(isVisible && styles.stageVisible) }}>
                    <MdWeb style={styles.icon} />
                    <span style={styles.label}>Web App</span>
                </div>
                <div style={{ ...styles.stageItem, ...(isVisible && styles.stageVisible) }}>
                    <TbBrandAndroid style={styles.icon} />
                    <span style={styles.label}>Android App</span>
                </div>
                <div style={{ ...styles.stageItem, ...(isVisible && styles.stageVisible) }}>
                    <FaApple style={styles.icon} />
                    <span style={styles.label}>iOS App</span>
                </div>
            </div>
            <div style={styles.processContainer}>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <GoBug style={styles.icon} />
                    <span style={styles.label}>Testing</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <SiMake style={styles.icon} />
                    <span style={styles.label}>Build & Response</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <MdDeveloperMode style={styles.icon} />
                    <span style={styles.label}>Development Closure</span>
                </div>
                <div style={{ ...styles.processItem, ...(isVisible && styles.itemVisible) }}>
                    <MdOutlineContactSupport style={styles.icon} />
                    <span style={styles.label}>Support</span>
                </div>
            </div>
        </div>
    );
};

export default Process;
