import React, { useContext, useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const Industries = () => {
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            overflowX: 'hidden', // Prevent overflow
        },
        heading: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        industryGrid: {
            display: 'grid',
            gap: isSmallScreen || isMediumScreen ? '20px' : '30px',
            gridTemplateColumns: isSmallScreen ? 'repeat(1, 1fr)' : isMediumScreen ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
        },
        industryItem: {
            backgroundColor: darkMode ? '#444' : '#f5f5f5',
            borderRadius: '15px',
            padding: isSmallScreen || isMediumScreen ? '20px' : '30px',
            textAlign: 'left',
            opacity: 0,
            transform: 'translateX(-50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        industryItemVisible: {
            opacity: 1,
            transform: 'translateX(0)',
        },
        industryIcon: {
            width: '80px',
            height: '80px',
            marginBottom: '10px',
        },
        industryTitle: {
            fontSize: '18px',
            fontWeight: 600,
            marginBottom: '10px',
        },
        industryList: {
            listStyle: 'none',
            padding: 0,
        },
        industryListItem: {
            fontSize: '14px',
            marginBottom: '5px',
        },
    };

    const industries = [
        { name: 'FinTech', icon: '/images/fintech.svg', items: ['Banking App', 'Wallet App', 'NFT App', 'Payment App', 'Blockchain App', 'Loan & Mortage App'] },
        { name: 'EdTech', icon: '/images/edtech.svg', items: ['Educational App', 'School App', 'Kid App', 'School Management'] },
        { name: 'PropTech', icon: '/images/proptech.svg', items: ['Real Estate App', 'Property Management Systems'] },
        { name: 'Retail & Commerce', icon: '/images/ecom.svg', items: ['E-Commerce App', 'Inventory Management App', 'Grocery Delivery App', 'Restaurant Management App', 'Food Delivery App'] },
        { name: 'Social & Lifestyle', icon: '/images/social.svg', items: ['Dating App', 'Social Media App', 'Audio & Video Streaming App', 'School Management'] },
        { name: 'Healthcare', icon: '/images/healthcare.svg', items: ['Gym App', 'Pharmacy App', 'Nutrition App', 'Doctor Clinic App', 'Hospital Management', 'Fitness Band App', 'Telemedicine App'] },
    ];

    return (
        <div ref={sectionRef} style={styles.container}>
            <Heading title={'Industries We Cover'} />
            <div style={styles.industryGrid}>
                {industries.map((industry, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.industryItem,
                            ...(isVisible && styles.industryItemVisible),
                            transform: isVisible ? 'translateX(0)' : index % 2 === 0 ? 'translateX(-100%)' : 'translateX(100%)',
                        }}
                    >
                        <img src={industry.icon} alt={industry.name} style={styles.industryIcon} />
                        <h3 style={styles.industryTitle}>{industry.name}</h3>
                        <ul style={styles.industryList}>
                            {industry.items.map((item, idx) => (
                                <li key={idx} style={styles.industryListItem}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Industries;
