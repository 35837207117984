import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import ServicesHeroSection from "../Components/ServicesHeroSection";
import Testimonials from "../Components/Testimonials";
import KeyFeatures from "../Components/KeyFeatures";
import AllServices from "../Components/AllServices";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";

export default function TechnicalServices() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }, [])

    return (
        <>
            {
                loading ?
                    <>
                        <Loader />
                    </>
                    :
                    <>
                        <Header />
                        <ServicesHeroSection type='technical' />
                        <KeyFeatures type='technical' />
                        <AllServices type='technical' />
                        <Newsletter />
                        <Footer />
                    </>
            }
        </>
    )
}