import React, { useContext } from 'react';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const Gallery = ({ images }) => {

    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        galleryContainer: {
            display: 'grid',
            width: '100%',
            gap: '0',
        },
        galleryItem: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        galleryContainerDesktop: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
        },
        galleryContainerTablet: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        galleryContainerMobile: {
            gridTemplateColumns: '1fr',
        },
    };

    const getGalleryContainerStyle = () => {
        if (window.innerWidth <= 768) {
            return styles.galleryContainerMobile;
        } else if (window.innerWidth <= 1024) {
            return styles.galleryContainerTablet;
        } else {
            return styles.galleryContainerDesktop;
        }
    };

    return (
        <>
            <div style={{ background: darkMode ? primaryDarkColor : 'white', margin: 0 }}>
                <Heading title={'Gallery'} />
            </div>
            <div style={{ ...styles.galleryContainer, ...getGalleryContainerStyle() }}>
                {images.map((image, index) => (
                    <div key={index} style={styles.galleryItem}>
                        <img src={`/images/${image.src}`} alt={image.alt} style={styles.image} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default Gallery;
