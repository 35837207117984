import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

export default function ServiceApp() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Mobile App Development Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating innovative and user-centric mobile
                        applications that cater to diverse business needs. Our mobile app development services are
                        designed to leverage cutting-edge technologies and industry best practices to deliver robust,
                        scalable, and engaging solutions.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Custom Mobile App Development",
        "description": "We develop custom mobile applications tailored to your unique business requirements and goals. Whether you need a native app for iOS or Android or a cross-platform solution, our team ensures seamless functionality and intuitive user experiences."
    },
    {
        "title": "UI/UX Design",
        "description": "Our expert designers create captivating user interfaces (UI) and intuitive user experiences (UX) that enhance usability and engagement. We prioritize usability testing and user feedback to ensure that the app design aligns with your brand identity and meets user expectations."
    },
    {
        "title": "Native and Cross-Platform Development",
        "description": "Depending on your needs and budget, we offer both native app development (using Swift for iOS and Kotlin/Java for Android) and cross-platform development (using frameworks like React Native or Flutter). This approach ensures broader reach and consistent performance across different devices."
    },
    {
        "title": "E-commerce and Enterprise Apps",
        "description": "We specialize in developing e-commerce apps that drive sales and enhance customer experience through secure payment gateways, personalized shopping experiences, and seamless checkout processes. Additionally, we build enterprise apps that streamline business operations, improve productivity, and facilitate collaboration."
    },
    {
        "title": "Integration and APIs",
        "description": "Our developers integrate third-party APIs and backend services to enhance app functionality, such as social media integration, payment gateways, geolocation services, and more. This ensures that your app is feature-rich and capable of meeting complex business requirements."
    },
    {
        "title": "Testing and Quality Assurance",
        "description": "We conduct rigorous testing throughout the development lifecycle to identify and fix bugs, ensure performance optimization, and maintain app stability across different devices and operating systems."
    },
    {
        "title": "Maintenance and Support",
        "description": "Beyond deployment, we provide ongoing maintenance and support services to keep your app updated with the latest features and security patches. Our support team is available to address any issues promptly and ensure uninterrupted app performance."
    },
    {
        "title": "Client-Centric Approach",
        "description": "We prioritize transparency, communication, and collaboration throughout the development process. Our goal is to deliver high-quality apps that exceed client expectations and drive business success."
    }
]
