import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

const ServiceWebsite = () => {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Web Design & Development Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in providing comprehensive web design and
                        development solutions that cater to the specific needs of businesses across various industries.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        title: 'Custom Web Design',
        description: 'We focus on creating unique, visually appealing websites that are tailored to reflect your brand identity and resonate with your target audience. Our designs are crafted with user experience in mind, ensuring intuitive navigation and engaging visuals.',
        icon: '🎨',
    },
    {
        title: 'Responsive Web Development',
        description: 'Every website we build is responsive, adapting seamlessly to different devices and screen sizes. This ensures an optimal viewing and interactive experience for users, whether they are accessing your site on a desktop, tablet, or smartphone.',
        icon: '📱',
    },
    {
        title: 'E-commerce Solutions',
        description: 'We develop robust e-commerce platforms that facilitate online transactions efficiently. Our solutions are designed to enhance user engagement, improve conversion rates, and integrate seamlessly with payment gateways and inventory systems.',
        icon: '🛒',
    },
    {
        title: 'Content Management Systems (CMS)',
        description: 'Implementing user-friendly CMS platforms like WordPress, Drupal, or custom solutions, we empower you to manage and update your website content effortlessly. This flexibility allows you to keep your site dynamic and relevant to your audience.',
        icon: '📝',
    },
    {
        title: 'Website Maintenance & Support',
        description: 'Beyond initial development, we offer ongoing maintenance and support services to ensure your website remains secure, up-to-date, and optimized for performance. This includes regular updates, security patches, and technical support to address any issues promptly.',
        icon: '🔧',
    },
    {
        title: 'Expertise & Experience',
        description: 'With years of experience in the industry, our team of skilled designers and developers has successfully delivered projects ranging from simple websites to complex enterprise solutions. We stay abreast of industry trends and best practices to deliver solutions that align with your business goals.',
        icon: '💼',
    },
    {
        title: 'Innovative Solutions',
        description: 'We leverage the latest technologies and design trends to deliver innovative solutions that meet the evolving needs of your business and industry. Our approach is proactive, ensuring your website remains competitive in the digital landscape.',
        icon: '🚀',
    },
    {
        title: 'Client-Centric Approach',
        description: 'We prioritize clear communication, transparency, and collaboration throughout the project lifecycle. From initial consultation to final delivery, we work closely with you to understand your requirements and exceed your expectations.',
        icon: '🤝',
    },
];

export default ServiceWebsite;
