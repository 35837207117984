import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import Heading from './Heading';
import { useNavigate } from 'react-router-dom';
import { primaryDarkColor } from '../../constant';

const ServiceFeatures = ({ service }) => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '20px 20px 50px' : '3% 8% 8%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            textAlign: 'center',
            color: darkMode ? '#fff' : '#000',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            maxWidth: '800px',
            margin: '0 auto 40px auto',
        },
        cardsGrid: {
            display: 'grid',
            marginTop: '4%',
            gap: '35px',
            gridTemplateColumns: isSmallScreen
                ? 'repeat(1, 1fr)'
                : isMediumScreen
                    ? 'repeat(2, 1fr)'
                    : 'repeat(3, 1fr)',
        },
        card: {
            backgroundColor: darkMode ? '#282828' : 'white',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '8%',
            borderRadius: '15px',
            boxShadow: darkMode ? '0 4px 94px 0 #00000066' : '0 4px 94px 0 #00000026',
            textAlign: 'left',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        cardHeader: {
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '13px',
            marginBottom: '10px',
        },
        cardContent: {
            fontSize: '15px',
            color: darkMode ? '#bbb' : '#555',
        },
        cardHeading: {
            fontWeight: 600,
            fontSize: 25,
            margin: 0
        },
    };

    return (
        <div style={styles.container}>
            <Heading title={'Service Features'} />
            <div style={styles.cardsGrid}>
                {service?.features?.map((card, index) => (
                    <div key={index} className="card" style={styles.card}>
                        {/* <div style={{ ...styles.cardHeader, backgroundColor: service.badgeBg, color: service.badgeColor }}>{card?.tagline}</div> */}
                        <h3 style={styles.cardHeading}>{card?.title}</h3>
                        {/* <p style={styles.cardContent}>{card?.content}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceFeatures;