import React, { useContext, useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const technologies = [
    { name: 'Flutter', icon: '/images/flutter.svg' },
    { name: 'React Js', icon: '/images/react.svg' },
    { name: 'Next Js', icon: '/images/next-js.svg' },
    { name: 'My SQL', icon: '/images/mysql.svg' },
    { name: 'AWS', icon: '/images/aws.svg' },
    { name: 'Firebase', icon: '/images/firebase.svg' },
    { name: 'Mongo DB', icon: '/images/mongodb.svg' },
    { name: 'Express', icon: '/images/express.svg' },
    { name: 'Node Js', icon: '/images/node.svg' },
    { name: 'Azure', icon: '/images/azure.svg' },
    { name: 'Django', icon: '/images/django.svg' },
    { name: 'AI ML', icon: '/images/ai-ml.svg' },
];

const Technologies = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '20px' : '3% 13%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            overflow: 'hidden',
        },
        heading: {
            fontSize: '24px',
            fontWeight: '600',
            marginBottom: '30px',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? 'repeat(2, 1fr)' : isMediumScreen ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)',
            gap: '20px',
        },
        card: {
            backgroundColor: darkMode ? '#444' : '#f5f5f5',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(-50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        cardRight: {
            backgroundColor: darkMode ? '#444' : '#f5f5f5',
            borderRadius: '10px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        icon: {
            width: '60px',
            height: '60px',
            marginBottom: '10px',
        },
        name: {
            fontSize: '16px',
            fontWeight: '500',
        },
    };

    return (
        <div ref={sectionRef} style={styles.container}>
            <Heading title={'Technologies We Use'} />
            <div style={styles.grid}>
                {technologies.map((tech, index) => (
                    <div key={index}
                        style={index % 2 === 0 ? styles.card : styles.cardRight}
                    >
                        <img src={tech.icon} alt={tech.name} style={styles.icon} />
                        <p style={styles.name}>{tech.name}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Technologies;
