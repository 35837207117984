import React, { useContext } from 'react';
import { DarkModeContext } from './DarkModeContext';

export default function Heading({ title }) {

    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        header: {
            fontSize: '28px',
            fontWeight: 600,
            marginBottom: '20px',
            textAlign: 'center',
            color: darkMode ? '#fff' : '#000',
        }
    }

    return (
        <>
            <h2 style={styles.header}>{title}</h2>
        </>
    )
}
