import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceBrochure() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'brochure-design-1.webp', alt: 'Image 1' },
        { src: 'brochure-design-2.webp', alt: 'Image 2' },
        { src: 'brochure-design-3.webp', alt: 'Image 3' },
        { src: 'brochure-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Flyer & Brochure Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating compelling and visually appealing
                        flyers and brochures that effectively communicate your message, engage your audience, and
                        promote your products or services. Our design services are tailored to meet your specific
                        marketing goals and enhance your brand's visibility.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Strategic Consultation",
        "description": "We begin with a strategic consultation to understand your objectives, target audience, and key messaging. This insight guides our design process to ensure flyers and brochures align with your brand identity and marketing strategy."
    },
    {
        "title": "Customized Design Concepts",
        "description": "Our experienced designers craft customized flyer and brochure designs that capture attention and leave a lasting impression. Whether for promotional campaigns, events, or informational purposes, each design is tailored to meet your unique requirements."
    },
    {
        "title": "Creative Visual Elements",
        "description": "We incorporate captivating visuals, graphics, and typography to create designs that stand out. Clear and concise messaging is strategically integrated to convey your brand's value proposition and offerings effectively."
    },
    {
        "title": "Layout and Content Organization",
        "description": "We focus on intuitive layout and content organization to guide readers through information seamlessly. Whether designing a single-page flyer or a multi-page brochure, we prioritize readability and visual hierarchy."
    },
    {
        "title": "Brand Consistency",
        "description": "Maintaining consistency with your brand's visual identity is paramount. Our designs reflect your brand's color palette, fonts, and imagery to reinforce brand recognition and credibility across all marketing collateral."
    },
    {
        "title": "Versatility and Functionality",
        "description": "Our designs are versatile and functional, suitable for both digital distribution and print. We ensure files are delivered in high-resolution formats suitable for professional printing and optimized for online viewing."
    },
    {
        "title": "Platform-Specific Optimization",
        "description": "For digital distribution, we optimize designs to meet platform specifications and user experience expectations. This includes maintaining file sizes for quick downloads and ensuring compatibility with various devices and screen sizes."
    },
    {
        "title": "Call-to-Action (CTA) Integration",
        "description": "Each flyer and brochure design includes strategic placement of clear and compelling calls-to-action (CTAs). Whether directing recipients to visit your website, contact your business, or redeem an offer, CTAs are designed to drive engagement and conversions."
    },
    {
        "title": "Quality Printing and Delivery",
        "description": "For printed materials, we ensure high-quality printing materials and finishes that reflect professionalism and durability. Digital files are prepared for seamless printing with trusted vendors or distribution via email and digital platforms."
    },
    {
        "title": "Client Collaboration and Feedback",
        "description": "We value client collaboration throughout the design process and welcome feedback to refine designs until they meet your satisfaction. Our goal is to deliver designs that exceed your expectations and align with your marketing objectives."
    }
]
