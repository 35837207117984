import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import Heading from './Heading';
import { useNavigate } from 'react-router-dom';
import { brandingServices, primaryDarkColor, technicalServices } from '../../constant';

const AllServices = ({ type }) => {

    const navigate = useNavigate()
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '20px 20px 50px' : '3% 8% 8%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            textAlign: 'center',
            color: darkMode ? '#fff' : '#000',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            maxWidth: '800px',
            margin: '0 auto 40px auto',
        },
        cardsGrid: {
            display: 'grid',
            marginTop: '4%',
            gap: '35px',
            gridTemplateColumns: isSmallScreen
                ? 'repeat(1, 1fr)'
                : isMediumScreen
                    ? 'repeat(2, 1fr)'
                    : 'repeat(3, 1fr)',
        },
        card: {
            backgroundColor: darkMode ? '#282828' : '#F5F5F5',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '15% 8%',
            borderRadius: '15px',
            boxShadow: darkMode ? '0 4px 94px 0 #00000066' : '0 4px 94px 0 #00000026',
            textAlign: 'left',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        cardHeader: {
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '5px',
            fontSize: '13px',
            marginBottom: '10px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            marginBottom: '20px',
        },
        cardContent: {
            fontSize: '15px',
            color: darkMode ? '#bbb' : '#555',
        },
        cardHeading: {
            fontWeight: 600,
            fontSize: 20,
            marginBottom: 0,
        },
        serviceArrow: {
            fontSize: '20px',
            cursor: 'pointer',
            color: darkMode ? 'white' : 'black',
        },
    };

    function convertToKebabCase(input) {
        let lowerCaseString = input?.toLowerCase();
        let kebabCaseString = lowerCaseString?.replace(/\s+/g, '-');

        return kebabCaseString;
    }

    const handleServiceClick = (service) => {
        navigate(`/service/${service?.link}`)
        window.scrollTo(0, 0)
    }

    return (
        <div style={styles.container}>
            <Heading title={'All Services'} />
            <div style={styles.cardsGrid}>
                {
                    type === 'branding' ?
                        <>
                            {brandingServices.map((card, index) => (
                                <div key={index} style={styles.card} onClick={() => handleServiceClick(card)} className={darkMode ? 'darkIndustryItem card' : 'industryItem card'}>
                                    <div style={{ ...styles.cardHeader, backgroundColor: card.badgeBg, color: card.badgeColor }}>{card.badge}</div>
                                    <h3 style={styles.cardHeading}>{card.title}</h3>
                                    <p style={styles.cardContent}>{card.content}</p>
                                    <div style={styles.serviceArrow}>➔</div>
                                </div>
                            ))}
                        </>
                        :
                        <>
                            {technicalServices.map((card, index) => (
                                <div key={index} style={styles.card} onClick={() => handleServiceClick(card)} className={darkMode ? 'darkIndustryItem card' : 'industryItem card'}>
                                    <div style={{ ...styles.cardHeader, backgroundColor: card.badgeBg, color: card.badgeColor }}>{card.badge}</div>
                                    <h3 style={styles.cardHeading}>{card.title}</h3>
                                    <p style={styles.cardContent}>{card.content}</p>
                                    <div style={styles.serviceArrow}>➔</div>
                                </div>
                            ))}
                        </>
                }
            </div>
        </div>
    );
};

export default AllServices;