import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceLogoDesign() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'logo-design-1.webp', alt: 'Image 1' },
        { src: 'logo-design-2.webp', alt: 'Image 2' },
        { src: 'logo-design-3.webp', alt: 'Image 3' },
        { src: 'logo-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Logo Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating distinctive and memorable logos that
                        effectively represent your brand identity. Our logo design services are tailored to capture the
                        essence of your business and resonate with your target audience.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Discovery and Research",
        "description": "We begin by understanding your brand, values, target market, and competition through detailed discovery and research. This helps us identify key design elements and ensure the logo aligns with your brand identity."
    },
    {
        "title": "Concept Development",
        "description": "Our experienced designers brainstorm and conceptualize multiple logo concepts based on the insights gathered. We explore various styles, fonts, colors, and graphical elements to create unique and impactful designs."
    },
    {
        "title": "Design Exploration and Iteration",
        "description": "We present initial logo concepts for your feedback and iterate based on your input. This collaborative process ensures that the final logo design reflects your vision while meeting design principles for scalability and versatility."
    },
    {
        "title": "Customization and Finalization",
        "description": "Once a concept is selected, we refine the chosen logo design to perfection. This includes adjusting colors, typography, and proportions to achieve a balanced and harmonious composition that enhances brand recognition."
    },
    {
        "title": "Versatile Formats",
        "description": "We provide the logo in versatile formats suitable for various applications, including digital and print. This includes high-resolution files in formats like JPG, PNG, EPS, and PDF to ensure compatibility across different platforms and media."
    },
    {
        "title": "Brand Consistency",
        "description": "Our logo designs are crafted to maintain consistency with your overall brand identity. Whether used on websites, marketing materials, or products, the logo reinforces brand recognition and builds trust among your audience."
    },
    {
        "title": "Scalability and Adaptability",
        "description": "We design logos that are scalable without loss of quality, ensuring clarity and impact across different sizes and mediums. This adaptability allows the logo to maintain its integrity whether displayed on a small business card or a large billboard."
    },
    {
        "title": "Trademark and Copyright",
        "description": "We prioritize legal considerations and provide guidance on trademarking your logo design to protect intellectual property rights. Our designs are original and created with adherence to copyright laws."
    },
    {
        "title": "Client Satisfaction Guarantee",
        "description": "Customer satisfaction is at the core of our service. We strive to exceed your expectations with professional, creative, and effective logo designs that resonate with your audience and support your brand's growth."
    }
]
