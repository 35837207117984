import React, { useContext, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import emailjs from 'emailjs-com';
import { primaryDarkColor } from '../../constant';

const ContactForm = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {
            to_name: 'Codestep Technologies',
            from_name: formData?.firstName + ' ' + formData?.lastName,
            email: formData?.email,
            message: formData?.message
        }
        emailjs.send(
            'service_jc8vopm',
            'template_xc1p9xx',
            payload,
            '29EF8Z6H3DqrJD3jn'
        ).then((result) => {
            setStatus(true);
            setLoading(false);
        }, (error) => {
            setStatus(false);
            setLoading(false);
        });
    };

    const styles = {
        container: {
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#f9f9f9',
            color: darkMode ? '#fff' : '#000',
            textAlign: 'center',
            position: 'relative'
        },
        heading: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '10px',
        },
        subHeading: {
            fontSize: '15px',
            color: darkMode ? '#aaa' : '#555',
            marginBottom: '40px',
        },
        formContainer: {
            maxWidth: '600px',
            margin: '0 auto',
            textAlign: 'left',
            zIndex: 99,
            position: 'relative'
        },
        formGroup: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            marginBottom: '20px',
        },
        formControl: {
            flex: 1,
            marginRight: isSmallScreen ? '0' : '10px',
            marginBottom: isSmallScreen ? '10px' : '0',
            marginTop: '3%'
        },
        input: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
            backgroundColor: darkMode ? '#555' : '#fff',
            color: darkMode ? '#fff' : '#000',
            fontSize: '16px',
            boxSizing: 'border-box',
        },
        textarea: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
            backgroundColor: darkMode ? '#555' : '#fff',
            color: darkMode ? '#fff' : '#000',
            fontSize: '16px',
            minHeight: '150px',
            marginBottom: '20px',
            boxSizing: 'border-box',
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px 30px',
            borderRadius: '5px',
            fontSize: '16px',
            border: 'none',
            cursor: 'pointer',
        },
        lineImage: {
            position: 'absolute',
            top: '0',
            right: '-27%',
            transform: 'translateX(-50%)',
            zIndex: 1,
        },
        messageBox: {
            padding: '15px',
            borderRadius: '5px',
            marginTop: '20px',
            textAlign: 'center',
            backgroundColor: status ? '#d4edda' : '#f8d7da',
            color: status ? '#155724' : '#721c24',
            border: `1px solid ${status ? '#c3e6cb' : '#f5c6cb'}`
        },
    };

    return (
        <div style={styles.container}>
            <img src='/images/line.svg' style={styles.lineImage} />
            <h2 style={styles.heading}>Contact us directly!</h2>
            <p style={styles.subHeading}>Fill the info below and mail us</p>
            <form style={styles.formContainer} onSubmit={handleSubmit}>
                <div style={styles.formGroup}>
                    <div style={{ ...styles.formControl }}>
                        <label>First Name</label>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            style={styles.input}
                            required
                        />
                    </div>
                    <div style={styles.formControl}>
                        <label>Last Name</label>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            style={styles.input}
                            // required
                        />
                    </div>
                </div>
                <div style={styles.formControl}>
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.formControl}>
                    <label>Message</label>
                    <textarea
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        style={styles.textarea}
                        required
                    ></textarea>
                </div>
                <button type="submit" style={styles.button}>{loading ? 'Sending...' : 'Get in touch'}</button>
            </form>
            {status !== null && (
                <div style={styles.messageBox}>
                    {status ? 'Message has been sent successfully!' : 'Failed to send message. Please try again.'}
                </div>
            )}
        </div>
    );
};

export default ContactForm;
