import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const ContactHeroSection = () => {
    
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px 10px' : '70px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            textAlign: 'center',
        },
        breadcrumb: {
            fontSize: '14px',
            color: darkMode ? '#bbb' : '#aaa',
            marginBottom: '20px',
        },
        header: {
            fontSize: '32px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        subHeader: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            marginBottom: '40px',
        },
        mapContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        mapImage: {
            width: '100%',
            maxWidth: '800px',
        },
        locationMarker: {
            position: 'absolute',
            width: '30px',
            height: '30px',
            backgroundColor: '#FF7A00',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: '16px',
        },
    };

    const markers = [
        { top: '30%', left: '20%' },
        { top: '50%', left: '50%' },
        { top: '70%', left: '30%' },
        { top: '40%', left: '70%' },
    ];

    return (
        <div style={styles.container}>
            <div style={styles.breadcrumb}>Home &gt; Contact us</div>
            <h1 style={styles.header}>Let us connect!</h1>
            <p style={styles.subHeader}>
                Contact us through multiple ways.
            </p>
            <div style={styles.mapContainer}>
                <div style={{ position: 'relative' }}>
                    <img src="/images/world-map.png" alt="World Map" style={styles.mapImage} />
                </div>
            </div>
        </div>
    );
};

export default ContactHeroSection;