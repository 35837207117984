import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const AboutDescription = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#F5F5F5',
            color: darkMode ? '#fff' : '#000',
        },
        textContainer: {
            flex: 1,
            paddingLeft: isSmallScreen ? '0' : '20px',
            textAlign: isSmallScreen ? 'center' : 'left',
        },
        heading: {
            fontSize: '28px',
            fontWeight: 600,
            marginBottom: '20px',
            width: isMediumScreen || isSmallScreen ? '' : '80%',
        },
        subHeading: {
            fontSize: '16px',
            color: darkMode ? 'white' : 'black',
            marginBottom: '10px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isMediumScreen || isSmallScreen ? '' : '80%',
            marginBottom: '20px',
            lineHeight: '1.6',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: isSmallScreen ? '20px' : '0',
        },
        image: {
            width: isSmallScreen || isMediumScreen ? '85%' : '65%',
            borderRadius: '10px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.imageContainer}>
                <img src="/images/about-image-2.png" alt="About Description" style={styles.image} />
            </div>
            <div style={styles.textContainer}>
                {/* <p style={styles.subHeading}>Lorem ipsum</p> */}
                <h2 style={styles.heading}>Our Mission</h2>
                <p style={styles.description}>
                    Our mission is to exceed client expectations by delivering high-quality, tailored
                    solutions through collaborative teamwork and a focus on customer satisfaction. We aim to
                    foster growth, reliability, and trust in every project we undertake.
                </p>
            </div>
        </div>
    );
};

export default AboutDescription;