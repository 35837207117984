import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './website/Pages/Home';
import About from './website/Pages/About';
import Contact from './website/Pages/Contact';
import { DarkModeContext, DarkModeProvider } from './website/Components/DarkModeContext';
import TechnicalServices from './website/Pages/TechnicalServices';
import BrandingServices from './website/Pages/BrandingServices';
import ServicePage from './website/Pages/ServicePage';
import React, { useContext } from 'react';
import ServiceWebsite from './website/Pages/ServiceWebsite';
import ServiceApp from './website/Pages/ServiceApp';
import ServiceUI from './website/Pages/ServiceUI';
import ServiceSoftware from './website/Pages/ServiceSoftware';
import ServiceEcom from './website/Pages/ServiceEcom';
import ServiceWhiteLabel from './website/Pages/ServiceWhiteLabel';
import ServiceProductDesign from './website/Pages/ServiceProductDesign';
import ServiceLogoDesign from './website/Pages/ServiceLogoDesign';
import ServiceBusinessCard from './website/Pages/ServiceBusinessCard';
import ServiceSocialMedia from './website/Pages/ServiceSocialMedia';
import ServiceBanner from './website/Pages/ServiceBanner';
import ServiceBrochure from './website/Pages/ServiceBrochure';
import ServicePackaging from './website/Pages/ServicePackaging';
import { primaryDarkColor } from './constant';

function App() {
  return (
    <DarkModeProvider>
      <AppContent />
    </DarkModeProvider>
  );
}

function AppContent() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className="App" style={{ background: darkMode ? primaryDarkColor : 'white' }}>
      <Router>
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<TechnicalServices />} path="/services/technical-services" />
          <Route element={<BrandingServices />} path="/services/branding-services" />
          <Route element={<About />} path="/about" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<ServicePage />} path="/service/:service" />

          <Route element={<ServiceWebsite />} path="/service/website-development" />
          <Route element={<ServiceApp />} path="/service/mobile-app" />
          <Route element={<ServiceUI />} path="/service/ui-ux" />
          <Route element={<ServiceSoftware />} path="/service/software-development" />
          <Route element={<ServiceEcom />} path="/service/ecommerce-development" />
          <Route element={<ServiceWhiteLabel />} path="/service/white-label" />

          <Route element={<ServiceProductDesign />} path="/service/product-design" />
          <Route element={<ServiceLogoDesign />} path="/service/logo-design" />
          <Route element={<ServiceBusinessCard />} path="/service/business-card-design" />
          <Route element={<ServiceSocialMedia />} path="/service/social-media-post-design" />
          <Route element={<ServiceBanner />} path="/service/banner-design" />
          <Route element={<ServiceBrochure />} path="/service/flyer-brochure-design" />
          <Route element={<ServicePackaging />} path="/service/packaging-and-box-design" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
