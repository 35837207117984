import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Partners = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10%',
            backgroundColor: darkMode ? primaryDarkColor : '#F5F5F5',
            textAlign: 'center',
            color: darkMode ? '#fff' : '#000',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        partnersGrid: {
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: isSmallScreen
                ? 'repeat(2, 1fr)'
                : isMediumScreen
                    ? 'repeat(3, 1fr)'
                    : 'repeat(5, 1fr)',
            alignItems: 'center',
        },
        partnerItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        partnerImage: {
            maxWidth: '100%',
            height: 'auto',
        },
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 250,
        slidesToShow: isSmallScreen || isMediumScreen ? 2 : 4,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: false,
        autoplay: true,
    };

    const partners = [
        { src: '/images/university.png', alt: 'University Academics' },
        { src: '/images/Milkpromise.png', alt: 'Milk Promise Logo' },
        { src: '/images/mega.png', alt: 'Mega We Care' },
        { src: '/images/bs-properties.jpg', alt: 'BS Properties' },
        { src: '/images/Alk-Designs.png' , alt: 'Alk Designs'},
    ];

    return (
        <div style={styles.container}>
            <Heading title={'Our Clients'} />

            <Slider {...settings}>
                {partners.map((partner, index) => (
                    <div key={index}>
                        <div style={styles.partnerItem}>
                            <img src={partner.src} alt={partner.alt} style={styles.partnerImage} />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Partners;