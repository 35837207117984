import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

export default function ServiceUI() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        UI/UX Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating intuitive and visually appealing user
                        interfaces (UI) and seamless user experiences (UX) that enhance customer satisfaction and
                        engagement. Our UI/UX design services are crafted to align with your business objectives
                        and user needs.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "User Research and Analysis",
        "description": "We begin by conducting thorough user research to understand your target audience, their behaviors, preferences, and pain points. This helps us create user personas and user journeys that guide our design decisions."
    },
    {
        "title": "Wireframing and Prototyping",
        "description": "Our design process includes creating wireframes and prototypes that visualize the app or website structure and layout. This iterative approach allows us to refine design elements and interactions before moving to the development phase."
    },
    {
        "title": "Visual Design and Branding",
        "description": "We focus on creating cohesive visual designs that reflect your brand identity and resonate with your target audience. This includes selecting appropriate color schemes, typography, iconography, and imagery to create a compelling visual language."
    },
    {
        "title": "UI Design Implementation",
        "description": "Our UI designers translate wireframes and prototypes into pixel-perfect designs. We ensure that the UI is not only aesthetically pleasing but also functional, intuitive, and aligned with best practices in UI design."
    },
    {
        "title": "UX Design and Interaction Design",
        "description": "We prioritize usability and intuitive navigation in our UX design approach. This involves mapping out user flows, designing clear navigation paths, and optimizing interactions to enhance user satisfaction and task completion."
    },
    {
        "title": "Responsive Design",
        "description": "Ensuring a seamless experience across all devices is integral to our UX design process. We design responsive interfaces that adapt fluidly to different screen sizes and devices, maintaining usability and visual coherence."
    },
    {
        "title": "Usability Testing and Iteration",
        "description": "We conduct usability testing sessions to gather feedback from real users and identify areas for improvement. This iterative process allows us to refine the UI/UX design based on user insights and ensure optimal usability."
    },
    {
        "title": "Accessibility and Compliance",
        "description": "Our designs adhere to accessibility standards (such as WCAG) to ensure inclusivity and compliance with industry regulations. We prioritize accessibility features to accommodate users with diverse needs."
    },
    {
        "title": "Client Collaboration and Feedback",
        "description": "Throughout the design process, we maintain open communication with our clients to ensure alignment with their vision and goals. We value client feedback and incorporate it into our design iterations to achieve the desired outcomes."
    }
]
