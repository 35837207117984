import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceSocialMedia() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'social-design-1.webp', alt: 'Image 1' },
        { src: 'social-design-2.webp', alt: 'Image 2' },
        { src: 'social-design-3.webp', alt: 'Image 3' },
        { src: 'social-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Social Media Post Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating engaging and visually appealing social
                        media posts that captivate your audience and enhance brand visibility. Our social media post
                        designs are strategically crafted to align with your brand identity and marketing objectives.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Strategic Consultation",
        "description": "We begin with a strategic consultation to understand your social media goals, target audience, and content strategy. This enables us to design posts that resonate with your followers and support your business objectives."
    },
    {
        "title": "Customized Design Concepts",
        "description": "Our talented designers conceptualize unique and creative social media post designs tailored to your brand. Whether promoting products, sharing updates, or running campaigns, each post is designed to stand out in the crowded social media landscape."
    },
    {
        "title": "Visual Storytelling",
        "description": "We focus on visual storytelling to convey your brand message effectively. Through compelling graphics, imagery, and typography, we create posts that evoke emotions, drive engagement, and encourage audience interaction."
    },
    {
        "title": "Brand Consistency",
        "description": "We ensure consistency with your brand's visual identity across all social media posts. From color palette and typography to logo placement and design elements, each post reinforces brand recognition and strengthens brand equity."
    },
    {
        "title": "Platform Optimization",
        "description": "Our designs are optimized for various social media platforms such as Facebook, Instagram, Twitter, LinkedIn, and more. We adapt the dimensions, formats, and content style to maximize visibility and engagement on each platform."
    },
    {
        "title": "Call-to-Action (CTA) Integration",
        "description": "Each social media post is strategically crafted with clear and compelling calls-to-action (CTAs) to drive user interaction and conversions. Whether directing followers to visit your website, sign up for a newsletter, or make a purchase, CTAs are seamlessly integrated into the design."
    },
    {
        "title": "Seasonal Campaigns and Trends",
        "description": "We stay updated with current trends and seasonal themes to create relevant and timely social media posts. This proactive approach ensures your content remains fresh, resonates with your audience, and aligns with industry trends."
    },
    {
        "title": "Analytics and Performance Tracking",
        "description": "We monitor the performance of social media posts and analyze engagement metrics such as likes, shares, comments, and click-through rates. This data-driven approach helps optimize future campaigns and content strategies for maximum impact."
    },
    {
        "title": "Monthly Subscription Service",
        "description": "In addition to one-time design services, we offer monthly subscription packages for ongoing social media post creation. This ensures consistent, high-quality content delivery that keeps your social media channels active and engaging."
    },
    {
        "title": "Client Collaboration and Feedback",
        "description": "We value client collaboration and encourage feedback throughout the design process. Your input helps us tailor social media post designs to your preferences and marketing goals, ensuring satisfaction and alignment with your brand vision."
    }
]