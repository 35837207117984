import { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

export default function Loader() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    return (
        <>
            <div style={{ background: darkMode ? primaryDarkColor : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className={darkMode ? 'loading-dark' : 'loading'}></div>
                <div className='loading'></div>
                <div className='loading'></div>
            </div>
        </>
    )
}