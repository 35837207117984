import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceBusinessCard() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'card-design-1.webp', alt: 'Image 1' },
        { src: 'card-design-2.webp', alt: 'Image 2' },
        { src: 'card-design-3.webp', alt: 'Image 3' },
        { src: 'card-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Business Card Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we offer professional and customized business card design
                        services to make a lasting impression on your clients and business contacts. Our business
                        card designs are crafted with creativity, attention to detail, and alignment with your brand
                        identity.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Consultation and Conceptualization",
        "description": "We begin by consulting with you to understand your business objectives, target audience, and branding preferences. This initial discussion guides our designers in conceptualizing business card designs that reflect your professional image."
    },
    {
        "title": "Customized Design Solutions",
        "description": "Our experienced designers create bespoke business card designs tailored to your specific requirements. Whether you prefer a minimalist approach, bold graphics, or sophisticated typography, we ensure the design resonates with your brand identity."
    },
    {
        "title": "Brand Integration",
        "description": "We integrate your logo, color scheme, and brand elements into the business card design to maintain consistency across all marketing materials. This reinforces brand recognition and enhances credibility with every interaction."
    },
    {
        "title": "Creative Layout and Typography",
        "description": "We pay meticulous attention to the layout, typography, and visual hierarchy of the business card design. Clear and concise information placement ensures that key details such as your name, title, contact information, and website are easily accessible."
    },
    {
        "title": "High-Quality Printing Materials",
        "description": "Upon finalizing the design, we provide high-resolution files optimized for printing. We recommend quality printing materials such as premium cardstock, matte or glossy finishes, and options for embossing or foil stamping to elevate the card's aesthetic appeal."
    },
    {
        "title": "Versatility and Functionality",
        "description": "Our business card designs are versatile and functional, suitable for various industries and networking occasions. Whether you're attending conferences, meetings, or networking events, your business card will make a professional statement."
    },
    {
        "title": "Digital Formats",
        "description": "In addition to print-ready files, we provide digital formats of the business card design for electronic sharing via email or digital platforms. This ensures consistency in your brand representation across both print and digital media."
    },
    {
        "title": "Timely Delivery and Customer Satisfaction",
        "description": "We prioritize timely delivery of finalized designs and ensure customer satisfaction throughout the process. Our goal is to exceed your expectations with business card designs that effectively communicate your brand's professionalism and reliability."
    },
    {
        "title": "Revisions and Feedback",
        "description": "We welcome your feedback and offer revisions to refine the business card design until it meets your approval. This collaborative approach ensures that the final product aligns perfectly with your vision and marketing goals."
    }
]
