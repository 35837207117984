import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const AboutHeroSection = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        textContainer: {
            flex: 1,
            paddingRight: isSmallScreen ? '0' : '20px',
            paddingLeft: isSmallScreen ? '0' : '5%',
            marginBottom: isSmallScreen ? '20px' : '0',
            textAlign: isSmallScreen ? 'center' : 'left',
            paddingTop: isMediumScreen || isSmallScreen ? '10%' : '',
            paddingBottom: isMediumScreen || isSmallScreen ? '10%' : ''
        },
        breadcrumb: {
            fontSize: '14px',
            color: darkMode ? '#bbb' : '#aaa',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '32px',
            width: isSmallScreen || isMediumScreen ? '' : '80%',
            fontWeight: 600,
            marginBottom: '20px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: '80%',
            margin: isSmallScreen || isMediumScreen ? '0 auto 20px auto' : '0 0 20px 0',
            textAlign: 'justify'
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px 30px',
            borderRadius: '5px',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        image: {
            width: '100%',
        },
        imageNav: {
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px 20px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '5px',
            color: '#fff',
        },
        navButton: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#fff',
            fontSize: '16px',
            cursor: 'pointer',
            margin: '0 5px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.textContainer}>
                <div style={styles.breadcrumb}>Home &gt; About us</div>
                <h2 style={styles.heading}>About CodeStep Technologies</h2>
                <p style={styles.description}>
                    CodeStep Technologies is a leading provider of tailored software solutions and branding
                    services, dedicated to delivering excellence in technology and design. Specializing in custom
                    software development and mobile app solutions, we empower businesses to streamline
                    operations and enhance user experiences. Our branding services focus on creating impactful
                    brand identities through logo design, graphic design, and digital marketing strategies. Beyond
                    project delivery, we offer comprehensive after-sales support, including technical assistance,
                    maintenance services, and continuous improvement to ensure sustained client success.
                    Partner with us to achieve your digital goals with innovation and reliability
                </p>
                <a href="#" style={styles.button}>Get in touch</a>
            </div>
            <div style={styles.imageContainer}>
                <img src="/images/workspace-image.png" alt="About Us" style={styles.image} />
            </div>
        </div>
    );
};

export default AboutHeroSection;