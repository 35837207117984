export const primaryDarkColor = 'black'

export const technicalServices = [
    {
        link: 'website-development', iconKey: 'IoLaptopOutline', banner: 'web-banner.png', badge: 'Technical Service', title: 'Web Design & Development', badgeBg: '#B000DC1C', badgeColor: '#B000DC', content: "At CodeStep Technologies, we specialize in providing comprehensive web design and development solutions that cater to the specific needs of businesses across various industries."
    },
    {
        link: 'mobile-app', iconKey: 'MdDeveloperMode', banner: 'web-banner.png', badge: 'Technical Service', title: 'Mobile App Development', badgeBg: '#DC42001C', badgeColor: '#DC1A00', content: "At CodeStep Technologies, we specialize in creating innovative and user-centric mobile applications that cater to diverse business needs. Our mobile app development services are designed to leverage cutting-edge technologies and industry best practices to deliver robust, scalable, and engaging solutions."
    },
    {
        link: 'ui-ux', iconKey: 'MdOutlineDesignServices', banner: 'web-banner.png', badge: 'Technical Service', title: 'UI/UX', badgeBg: '#00DC8D1C', badgeColor: '#00DC72', content: "At CodeStep Technologies, we specialize in creating intuitive and visually appealing user interfaces (UI) and seamless user experiences (UX) that enhance customer satisfaction and engagement. Our UI/UX design services are crafted to align with your business objectives and user needs."
    },
    {
        link: 'software-development', iconKey: 'IoMdCodeWorking', banner: 'web-banner.png', badge: 'Technical Service', title: 'Software Development', badgeBg: '#0058DC1C', badgeColor: '#0058DC', content: "At CodeStep Technologies, we specialize in delivering robust and scalable software solutions tailored to meet the unique needs of businesses across various industries. Our software development services are designed to streamline operations, enhance productivity, and drive digital transformation."
    },
    {
        link: 'ecommerce-development', iconKey: 'BsCart2', banner: 'web-banner.png', badge: 'Technical Service', title: 'E-Commerce Website', badgeBg: '#DC42001C', badgeColor: '#DC1A00', content: "At CodeStep Technologies, we specialize in creating robust and user-friendly E-Commerce websites that drive sales, enhance user experience, and streamline online transactions. Our E Commerce solutions are designed to meet the unique needs of businesses looking to establish or expand their online presence."
    },
    {
        link: 'white-label', iconKey: 'CgWebsite', banner: 'web-banner.png', badge: 'Technical Service', title: 'White-Labeled Website', badgeBg: '#00DC8D1C', badgeColor: '#00DC72', content: "At CodeStep Technologies, we specialize in creating white-labeled websites that allow businesses to resell or rebrand under their own brand identity. Our white-labeled website solutions are designed to empower agencies, freelancers, and businesses looking to offer professional web solutions without the need for in-house development."
    },
]



export const brandingServices = [
    {
        link: 'product-design', iconKey: 'PiPaintBrushBold', banner: 'web-banner.png', badge: 'Branding Service', title: 'Product Design', badgeBg: '#B000DC1C', badgeColor: '#B000DC', content: "At CodeStep Technologies, we specialize in comprehensive product design services that encompass the entire lifecycle of product development. Our approach combines creativity, technical expertise, and user-centric design principles to create innovative and market-ready products."
    },
    {
        link: 'logo-design', iconKey: 'IoLogoClosedCaptioning', banner: 'web-banner.png', badge: 'Branding Service', title: 'Logo Design', badgeBg: '#DC42001C', badgeColor: '#DC1A00', content: "At CodeStep Technologies, we specialize in creating distinctive and memorable logos that effectively represent your brand identity. Our logo design services are tailored to capture the essence of your business and resonate with your target audience."
    },
    {
        link: 'business-card-design', iconKey: 'FaAddressCard', banner: 'web-banner.png', badge: 'Branding Service', title: 'Business Card Design', badgeBg: '#00DC8D1C', badgeColor: '#00DC72', content: "At CodeStep Technologies, we offer professional and customized business card design services to make a lasting impression on your clients and business contacts. Our business card designs are crafted with creativity, attention to detail, and alignment with your brand identity."
    },
    {
        link: 'social-media-post-design', iconKey: 'BsFillFileEarmarkPostFill', banner: 'web-banner.png', badge: 'Branding Service', title: 'Social Media Post Design', badgeBg: '#0058DC1C', badgeColor: '#0058DC', content: "At CodeStep Technologies, we specialize in creating engaging and visually appealing social media posts that captivate your audience and enhance brand visibility. Our social media post designs are strategically crafted to align with your brand identity and marketing objectives."
    },
    {
        link: 'banner-design', iconKey: 'CgDisplayFullwidth', banner: 'web-banner.png', badge: 'Branding Service', title: 'Banner Design', badgeBg: '#DC42001C', badgeColor: '#DC1A00', content: "At CodeStep Technologies, we specialize in creating eye-catching and effective banners that grab attention, convey key messages, and promote brand awareness. Our banner designs are tailored to meet your marketing objectives and engage your target audience."
    },
    {
        link: 'flyer-brochure-design', iconKey: 'SiAffinitydesigner', banner: 'web-banner.png', badge: 'Branding Service', title: 'Flyer & Brochure Design', badgeBg: '#00DC8D1C', badgeColor: '#00DC72', content: "At CodeStep Technologies, we specialize in creating compelling and visually appealing flyers and brochures that effectively communicate your message, engage your audience, and promote your products or services. Our design services are tailored to meet your specific marketing goals and enhance your brand's visibility."
    },
    {
        link: 'packaging-and-box-design', iconKey: 'BiLabel', banner: 'web-bannerpng', badge: 'Branding Service', title: 'Packaging & Box Design', badgeBg: '#00DC8D1C', badgeColor: '#00DC72', content: "At CodeStep Technologies, we specialize in creating impactful and innovative packaging designs that enhance product appeal, engage consumers, and reinforce brand identity. Whether you're launching a new product or rebranding an existing one, our design services are tailored to meet your specific packaging needs"
    }
];