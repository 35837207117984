import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import AboutHeroSection from "../Components/AboutHeroSection";
import WhyChooseUs from "../Components/WhyChooseUs";
import AboutDescription from "../Components/AboutDescription";
import Partners from "../Components/Partners";
import Team from "../Components/Team";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";
import OurVision from "../Components/OurVision";

export default function About() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }, [])

    return (
        <>
            {
                loading ?
                    <>
                        <Loader />
                    </>
                    :
                    <>
                        <Header />
                        <AboutHeroSection />
                        <OurVision />
                        <AboutDescription />
                        <Team />
                        <Partners />
                        <Newsletter />
                        <Footer />
                    </>
            }
        </>
    )
}