import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { DarkModeContext } from './DarkModeContext';
import { useNavigate } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";
import PhoneIcon from '@mui/icons-material/Phone';
import { MdOutlineMail } from "react-icons/md";
import { primaryDarkColor } from '../../constant';

const Footer = () => {

    const navigate = useNavigate()
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            backgroundColor: darkMode ? primaryDarkColor : '#1B1B1B',
            color: '#fff',
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isSmallScreen || isMediumScreen ? 'center' : 'flex-start',
            padding: isMediumScreen || isSmallScreen ? '20px' : '4% 8%'
        },
        column: {
            flex: 1,
            padding: '0 20px',
            marginBottom: isSmallScreen ? '20px' : '0',
            textAlign: isSmallScreen ? 'center' : 'left',
        },
        logo: {
            cursor: 'pointer'
        },
        description: {
            fontSize: '14px',
            marginBottom: '20px',
        },
        link: {
            color: '#fff',
            textDecoration: 'none',
            display: 'block',
            fontSize: 14,
            marginBottom: '10px',
            cursor: 'pointer'
        },
        socialIcons: {
            display: 'flex',
            justifyContent: isSmallScreen ? 'center' : 'flex-start',
            marginTop: '20px',
        },
        socialIcon: {
            width: '30px',
            height: '30px',
            marginRight: '10px',
        },
        bottomBar: {
            backgroundColor: darkMode ? '#333333' : '#1B1B1B',
            color: '#fff',
            padding: '10px 20px',
            fontSize: 13,
            textAlign: 'center',
        },
        hr: {
            border: 'none',
            margin: 0,
            borderTop: '1px solid gray',
        },
        heading: {
            fontWeight: 500,
        },
        socialIcons: {
            display: 'flex',
            gap: '10px',
            justifyContent: isMediumScreen || isSmallScreen ? 'center' : 'left'
        },
        socialIcon: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: darkMode ? '#555' : '#f9f9f9',
        },
    };

    return (
        <div>
            <div style={styles.container}>
                <div style={styles.column}>
                    <div style={styles.logo}>
                        <img src='/images/main-logo-white.png' style={{ width: 180 }} />
                    </div>
                    <p style={styles.description}>
                        Expertise in Software Development, Web Development, and Branding
                    </p>
                    <p style={{ ...styles.description, display: 'flex', alignItems: 'center', gap: 5, marginBottom: 0, justifyContent: isSmallScreen || isMediumScreen ? 'center' : '' }}>
                        <PhoneIcon style={{ width: 25, height: 25 }} />
                        +91 6362153519
                    </p>
                    <p style={{ ...styles.description, display: 'flex', alignItems: 'center', gap: 5, justifyContent: isSmallScreen || isMediumScreen ? 'center' : '' }}>
                        <MdOutlineMail style={{ width: 25, height: 25 }} />
                        contact@codesteptech.com
                    </p>
                </div>
                <div style={styles.column}>
                    <h3 style={styles.heading}>About us</h3>
                    <p style={styles.link} onClick={() => { navigate('/'); window.scrollTo(0, 0) }}>Home</p>
                    <p style={styles.link} onClick={() => { navigate('/services/technical-services'); window.scrollTo(0, 0) }}>Technical Services</p>
                    <p style={styles.link} onClick={() => { navigate('/services/branding-services'); window.scrollTo(0, 0) }}>Branding Services</p>
                    <p style={styles.link} onClick={() => { navigate('/contact'); window.scrollTo(0, 0) }}>Contact us</p>
                </div>
                <div style={styles.column}>
                    <h3 style={styles.heading}>Follow us</h3>
                    <p style={styles.description}>
                        Follow us on multiple channels
                    </p>
                    <div style={styles.socialIcons}>
                        <div style={styles.socialIcons}>
                            <a target='_blank' href="https://www.instagram.com/codestep_technologies/" style={styles.socialIcon}>
                                <AiFillInstagram style={{ color: darkMode ? 'white' : 'black' }} />
                            </a>
                            <a target='_blank' href="https://www.linkedin.com/company/codestep-technologies/" style={styles.socialIcon}>
                                <FaLinkedin style={{ color: darkMode ? 'white' : 'black' }} />
                            </a>
                            <a target='_blank' href="https://www.facebook.com/profile.php?id=61561375462349" style={styles.socialIcon}>
                                <FaFacebook style={{ color: darkMode ? 'white' : 'black' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={styles.hr} />
            <div style={styles.bottomBar}>
                Copyright © 2024 CodeStep Technologies All rights Reserved
            </div>
        </div >
    );
};

export default Footer;