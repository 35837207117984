import React, { useContext, useRef, useEffect, useState } from 'react';
import Heading from './Heading';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { useNavigate } from 'react-router-dom';
import { brandingServices, primaryDarkColor, technicalServices } from '../../constant';

import { PiPaintBrushBold } from "react-icons/pi";
import { IoLaptopOutline } from "react-icons/io5";
import { MdDeveloperMode } from "react-icons/md";
import { MdOutlineDesignServices } from "react-icons/md";
import { IoMdCodeWorking } from "react-icons/io";
import { BsCart2 } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { IoLogoClosedCaptioning } from "react-icons/io";
import { FaAddressCard } from "react-icons/fa";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { CgDisplayFullwidth } from "react-icons/cg";
import { SiAffinitydesigner } from "react-icons/si";
import { BiLabel } from "react-icons/bi";

const Services = () => {
    const navigate = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const isLargeScreen = useMediaQuery('(min-width: 1024px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const iconMap = {
        IoLaptopOutline: IoLaptopOutline,
        MdDeveloperMode: MdDeveloperMode,
        MdOutlineDesignServices: MdOutlineDesignServices,
        IoMdCodeWorking: IoMdCodeWorking,
        BsCart2: BsCart2,
        CgWebsite: CgWebsite,
        IoLogoClosedCaptioning: IoLogoClosedCaptioning,
        FaAddressCard: FaAddressCard,
        BsFillFileEarmarkPostFill: BsFillFileEarmarkPostFill,
        CgDisplayFullwidth: CgDisplayFullwidth,
        SiAffinitydesigner: SiAffinitydesigner,
        BiLabel: BiLabel,
        PiPaintBrushBold: PiPaintBrushBold
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isLargeScreen ? '50px 20px' : '30px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        visible: {
            opacity: 1,
            transform: 'translateY(0)',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            textAlign: 'center',
            maxWidth: '800px',
            marginBottom: '40px',
        },
        servicesGrid: {
            display: 'grid',
            gridTemplateColumns: isLargeScreen
                ? 'repeat(4, 1fr)'
                : isMediumScreen
                    ? 'repeat(3, 1fr)'
                    : 'repeat(2, 1fr)',
            gap: '20px',
        },
        serviceItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '20px',
            opacity: 0,
            transform: 'scale(0.5)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        serviceItemVisible: {
            opacity: 1,
            transform: 'scale(1)',
        },
        serviceIcon: {
            width: '60px',
            height: '60px',
            backgroundColor: '#EAEAEA',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '50%',
            color: 'black',
            justifyContent: 'center',
            marginBottom: '15px',
        },
        serviceTitle: {
            fontSize: '16px',
            fontWeight: 500,
            marginBottom: '10px',
        },
        serviceArrow: {
            fontSize: '20px',
            cursor: 'pointer',
            color: darkMode ? 'white' : 'black',
        },
        icon: {
            width: 25,
            height: 25
        },
    };

    const handleServiceClick = (service) => {
        navigate(`/service/${service?.link}`)
        window.scrollTo(0, 0);
    };

    return (
        <div ref={sectionRef} style={{ ...styles.container, ...(isVisible && styles.visible) }}>
            <Heading title="Services" />
            <p style={styles.description}>
                At Codestep Technologies, we specialize in delivering top-notch solutions that drive business
                success. Explore our core services.
            </p>
            <div style={styles.servicesGrid}>
                {technicalServices?.map((service, index) => {
                    const IconComponent = iconMap[service.iconKey];
                    return (
                        <div key={index} style={{ ...styles.serviceItem, ...(isVisible && styles.serviceItemVisible) }}>
                            <div style={styles.serviceIcon}>
                                {IconComponent && <IconComponent style={styles.icon} />}
                            </div>
                            <h3 style={styles.serviceTitle}>{service.title}</h3>
                            <div style={styles.serviceArrow} onClick={() => handleServiceClick(service)}>➔</div>
                        </div>
                    );
                })}
                {brandingServices?.map((service, index) => {
                    const IconComponent = iconMap[service.iconKey];
                    return (
                        <div key={index} style={{ ...styles.serviceItem, ...(isVisible && styles.serviceItemVisible) }}>
                            <div style={styles.serviceIcon}>
                                {IconComponent && <IconComponent style={styles.icon} />}
                            </div>
                            <h3 style={styles.serviceTitle}>{service.title}</h3>
                            <div style={styles.serviceArrow} onClick={() => handleServiceClick(service)}>➔</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Services;