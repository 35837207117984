import CTA from "../Components/CTA";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import HeroSection from "../Components/HeroSection";
import Industries from "../Components/Industries";
import Newsletter from "../Components/Newsletter";
import OurClients from "../Components/OurClients";
import Process from "../Components/Process";
import Services from "../Components/Services";
import Technologies from "../Components/Technologies";
import Testimonials from "../Components/Testimonials";
import WhyChooseUs from "../Components/WhyChooseUs";
import Loader from "../Components/Loader";
import { useEffect, useState } from "react";
import AppModal from "../Components/AppModal";
import Partners from "../Components/Partners";

export default function Home() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }, [])


    const [open, setOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 4000);
    }, [])


    return (
        <>
            {
                loading ?
                    <>
                        <Loader />
                    </>
                    :
                    <>
                        <AppModal open={open} setOpen={setOpen} />
                        <Header />
                        <HeroSection />
                        <Services />
                        {/* <OurClients /> */}
                        <Partners />
                        <Technologies />
                        <Process />
                        <WhyChooseUs />
                        <Industries />
                        <CTA />
                        <Testimonials />
                        <Newsletter />
                        <Footer />
                    </>
            }
        </>
    )
}