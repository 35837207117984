import React, { useContext, useState } from 'react';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from './DarkModeContext';
import PhoneIcon from '@mui/icons-material/Phone';
import { MdOutlineMail } from "react-icons/md";
import { primaryDarkColor } from '../../constant';

const Header = () => {

    const { darkMode, setDarkMode } = useContext(DarkModeContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [servicesOpen, setServicesOpen] = useState(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const isTabletOrMobile = useMediaQuery('(max-width: 767px)');
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
        },
        topBar: {
            display: isDesktop ? 'flex' : 'none',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 20px',
            color: darkMode ? '#b0b0b0' : '#000',
            height: 30,
            fontSize: '14px',
            backgroundColor: darkMode ? '#202020' : 'white',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
            padding: isDesktop ? '0 20px' : '10px 20px',
        },
        logo: {
            cursor: 'pointer'
        },
        nav: {
            display: isDesktop ? 'flex' : 'none',
            alignItems: 'center',
            position: 'relative',
        },
        navItem: {
            margin: '0 30px',
            color: darkMode ? 'white' : 'black',
            fontSize: '16px',
            textDecoration: 'none',
            cursor: 'pointer',
            position: 'relative',
            padding: '10px 0',
        },
        dropdownMenu: {
            position: 'fixed',
            top: '95px', // Adjust this value as needed to align with your header
            left: 0,
            right: 0,
            display: servicesOpen ? 'flex' : 'none',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            zIndex: 10,
            padding: '2px 20px',
            borderBottom: '1px solid gray',
            borderTop: '1px solid gray',
            justifyContent: 'space-between',
        },
        dropdownColumn: {
            flex: 1,
            padding: '20px',
            borderRight: '1px solid gray',
            '&:last-child': {
                borderRight: 'none',
            }
        },
        dropdownItem: {
            padding: '10px 20px',
            color: darkMode ? '#fff' : '#000',
            cursor: 'pointer',
            textAlign: 'left',
        },
        contactButton: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : 'white',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        contactButtonMobile: {
            backgroundColor: 'black',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            width: '50%',
            textAlign: 'center',
            marginTop: '10%',
            cursor: 'pointer',
            textDecoration: 'none',
        },
        drawerContent: {
            width: '250px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? '#181818' : '#fff',
            color: darkMode ? '#fff' : '#000',
            height: '100%',
        },
        drawerNavItem: {
            margin: '10px 0',
            color: darkMode ? '#fff' : '#000',
            fontSize: '16px',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        topBarItem: {
            marginBottom: '10px',
            color: darkMode ? '#b0b0b0' : '#000',
        },
        menuButton: {
            display: isDesktop ? 'none' : 'block',
            color: darkMode ? 'white' : 'black',
            display: 'flex',
            alignItems: 'center'
        },
        darkModeToggle: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        darkModeIcon: {
            marginLeft: '10px',
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px',
            borderRadius: '5px',
            marginLeft: '3%',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
        },
        serviceContainer: {
            padding: isDesktop ? '40px' : '20px',
            textAlign: isDesktop ? 'left' : 'center',
            maxWidth: '400px',
            margin: '0 auto'
        },
        heading: {
            fontSize: '24px',
            fontWeight: '600',
            marginBottom: '10px',
            color: darkMode ? 'white' : '#333'
        },
        description: {
            fontSize: '16px',
            color: '#555',
            marginBottom: '20px'
        },
        serviceButton: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px',
            borderRadius: '5px',
            fontSize: '16px',
            border: 'none',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
        },
        icon: {
            marginLeft: '10px',
            fontSize: '16px'
        },

        topBarContainer: {
            display: 'flex',
            justifyContent: isTabletOrMobile ? 'center' : 'flex-end',
            alignItems: 'center',
            padding: '5px 20px',
            backgroundColor: darkMode ? '#202020' : 'white',
            fontSize: '14px',
            gap: '10px',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            color: darkMode ? 'white' : '#000',
        },
        topBarIcon: {
            marginRight: '5px',
        },
        separator: {
            height: '20px',
            width: '2px',
            backgroundColor: '#ccc',
            margin: '0 10px',
        },
        link: {
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: darkMode ? 'white' : 'black'
        }
    };

    const handleClick = (pathDir) => {
        navigate(pathDir);
        window.scrollTo(0, 0);
    };


    const darkModeToggle = () => {

        const toggleStyles = {
            toggleContainer: {
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '20px',
                marginLeft: isTabletOrMobile ? 0 : '20px'
            },
            toggle: {
                width: '80px',
                height: '35px',
                backgroundColor: darkMode ? '#555555' : '#d1d1d1',
                borderRadius: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                transition: 'background-color 0.3s ease',
            },
            toggleCircle: {
                width: '25px',
                height: '25px',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
                position: 'absolute',
                right: darkMode ? '47%' : '',
                left: darkMode ? '' : '47%',
                transition: 'transform 0.3s ease',
                transform: darkMode ? 'translateX(30px)' : 'translateX(-30px)',
            },
            toggleLabel: {
                fontSize: 13,
                color: darkMode ? '#ffffff' : '#000000',
                fontWeight: '500',
                marginLeft: darkMode ? '' : '35%',
                marginRight: darkMode ? '35%' : '',
            }
        };

        return (
            <div style={toggleStyles.toggleContainer} onClick={toggleDarkMode}>
                <div style={toggleStyles.toggle}>
                    <div style={toggleStyles.toggleCircle}></div>
                    <p style={toggleStyles.toggleLabel}>{darkMode ? 'DARK' : 'LIGHT'}</p>
                </div>
            </div>
        );
    };


    return (
        <div style={styles.container}>
            {
                isDesktop && (
                    <div style={styles.topBarContainer}>
                        <div style={styles.item}>
                            <a href="mailto:contact@codesteptech.com" style={styles.link} >
                                <MdOutlineMail style={{ ...styles.topBarIcon, width: 26, height: 26 }} />
                                contact@codesteptech.com
                            </a>
                        </div>
                        <div style={styles.separator}></div>
                        <div style={styles.item}>
                            <a href="tel:+916362153519" style={styles.link}>
                                <PhoneIcon style={styles.topBarIcon} />
                                +91 6362153519
                            </a>
                        </div>
                    </div>
                )
            }
            <div style={styles.header}>
                <div style={styles.logo} onClick={() => handleClick('/')}>
                    <img src={darkMode ? '/images/main-logo-white.png' : '/images/main-logo-black.png'} style={{ width: 220 }} />
                </div>
                <div style={styles.nav}>
                    <p className={!darkMode ? 'nav-item-light' : 'nav-item'} style={styles.navItem} onClick={() => handleClick('/')}>Home</p>
                    <p
                        className={!darkMode ? 'nav-item-light' : 'nav-item'}
                        style={styles.navItem}
                        onMouseEnter={() => isDesktop && setServicesOpen(true)}
                        onMouseLeave={() => isDesktop && setServicesOpen(false)}
                        onClick={() => !isDesktop && setServicesOpen(!servicesOpen)}
                    >
                        Services {isTabletOrMobile && <ArrowDropDownIcon />}
                        <div style={styles.dropdownMenu}>
                            <div style={styles.dropdownColumn}>
                                <div style={styles.serviceContainer}>
                                    <h3 style={styles.heading}>Technical Services</h3>
                                    <p style={styles.description}>
                                        Our approach integrates a wide array of cutting-edge
                                        technologies and services.
                                    </p>
                                    <button style={styles.serviceButton} onClick={() => navigate('/services/technical-services')}>
                                        Learn more
                                        <span style={styles.icon}>➔</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn, borderRight: 'none' }}>
                                <div style={styles.serviceContainer}>
                                    <h3 style={styles.heading}>Branding Services</h3>
                                    <p style={styles.description}>
                                        Best if you need <strong>beautiful design & rapid development</strong> on a regular basis.
                                    </p>
                                    <button style={styles.serviceButton} onClick={() => navigate('/services/branding-services')}>
                                        Learn more
                                        <span style={styles.icon}>➔</span>
                                    </button>
                                </div>
                            </div>
                            <div style={{ ...styles.dropdownColumn, borderRight: 'none' }}>
                            </div>
                        </div>
                    </p>
                    <p className={!darkMode ? 'nav-item-light' : 'nav-item'} style={styles.navItem} onClick={() => handleClick('/about')}>About Us</p>
                    <p style={styles.contactButton} onClick={() => handleClick('/contact')}>Contact us</p>
                </div>
                {
                    isDesktop && (
                        // <div style={styles.darkModeToggle} onClick={toggleDarkMode}>
                        //     {darkMode ? <NightsStayIcon /> : <WbSunnyIcon />}
                        // </div>
                        darkModeToggle()
                    )
                }
                {
                    !isDesktop && (
                        <IconButton style={styles.menuButton} onClick={toggleDrawer(true)}>
                            <MenuIcon style={{ height: 35, width: 35 }} />
                        </IconButton>
                    )
                }
            </div>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div style={styles.drawerContent}>
                    <div style={styles.darkModeToggle}>
                        {darkModeToggle()}
                    </div>
                    {/* {darkModeToggle()} */}
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/')}>Home</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/services/technical-services')}>Technical Services</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/services/branding-services')}>Branding Services</p>
                    <p className="drawer-nav-item" style={styles.drawerNavItem} onClick={() => handleClick('/about')}>About Us</p>
                    <p style={styles.contactButtonMobile} onClick={() => handleClick('/contact')}>Contact us</p>
                </div>
            </Drawer>
        </div>
    );
};

export default Header;
