import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { TiLocation } from "react-icons/ti";
import { FaRegUser } from "react-icons/fa6";
import { GrProjects } from "react-icons/gr";
import { primaryDarkColor } from '../../constant';

const ServicesHeroSection = ({ type }) => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isMediumScreen || isSmallScreen ? '50px 20px' : '5% 13%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            textAlign: 'center',
            margin: isMediumScreen || isSmallScreen ? '' : 'auto',
            color: darkMode ? '#fff' : '#000',
        },
        breadcrumb: {
            fontSize: '14px',
            color: darkMode ? '#aaa' : '#aaa',
            marginBottom: '20px',
        },
        header: {
            fontSize: '32px',
            fontWeight: 600,
            marginBottom: '20px',
        },
        subHeader: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            margin: isSmallScreen || isMediumScreen ? '0 auto 40px auto' : '0 auto 40px auto',
            textAlign: isSmallScreen || isMediumScreen ? 'justify' : 'center'
        },
        statsContainer: {
            backgroundColor: darkMode ? '#282828' : '#fff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '15px',
            padding: isMediumScreen || isSmallScreen ? '' : '20px 0',
            boxShadow: darkMode ? '0 4px 94px 0 #00000066' : '0 4px 94px 0 #00000026',
        },
        statsItem: {
            borderRight: isMediumScreen || isSmallScreen ? '' : '2px solid #EEEFF2',
            padding: '20px',
            textAlign: 'center',
            flex: 1,
        },
        statsIcon: {
            width: 50,
            height: 50,
            marginBottom: '10px',
        },
        statsValue: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '5px',
        },
        statsLabel: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
        },
    };

    const stats = [
        { icon: <TiLocation tyle={styles.statsIcon} />, value: '30+', label: 'Clients' },
        { icon: <FaRegUser tyle={styles.statsIcon} />, value: '1+', label: 'Countries' },
        { icon: <GrProjects tyle={styles.statsIcon} />, value: '35+', label: 'Projects' },
    ];

    return (
        <div style={styles.container}>
            <div style={styles.breadcrumb}>Home &gt; Services</div>
            <h1 style={styles.header}>{type === 'branding' ? ' Elevate Your Brand with Codestep Branding' : 'Bespoke software development solutions for your business '}</h1>
            <p style={styles.subHeader}>
                {
                    type === 'branding' ?
                        <>
                            "Hello, Startups! Are you ready to make a splash in Shark Tank? Dive in with CodeStep's bespoke
                            branding services and make your mark."
                        </>
                        :
                        <>
                            At Codestep Technologies, we understand that every business is unique, with specific
                            processes, challenges, and goals. That's why we offer bespoke software development solutions
                            tailored to meet the precise needs of your business. Our expert team leverages the latest
                            technologies and industry best practices to deliver customized software that enhances efficiency,
                            drives innovation, and provides a significant return on investment.
                        </>
                }
            </p>
            <div style={styles.statsContainer}>
                {stats.map((stat, index) => (
                    <div key={index} style={{ ...styles.statsItem, borderRight: stats.length - 1 === index ? 'none' : '2px solid #EEEFF2' }}>
                        {/* {stat?.icon} */}
                        <div style={styles.statsValue}>{stat.value}</div>
                        <div style={styles.statsLabel}>{stat.label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesHeroSection;