import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const Testimonials = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const isLargeScreen = useMediaQuery('(min-width: 1024px)');

    const styles = {
        container: {
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#f5f5f5',
            overflow: 'hidden',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: 'left', // Align to the left
            marginBottom: '20px',
        },
        description: {
            fontSize: '14px',
            color: darkMode ? '#bbb' : '#555',
            textAlign: 'left', // Align to the left
            maxWidth: '800px',
            marginBottom: '40px',
            marginTop: 0,
            textAlign: 'center'
        },
        testimonialItem: {
            backgroundColor: darkMode ? '#333' : '#fff',
            padding: '30px',
            borderRadius: '15px',
            textAlign: 'left',
            margin: isLargeScreen ? '0 15px' : '0 5px',
            marginBottom: '6%',
        },
        badge: {
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '8px',
            backgroundColor: '#ff7a00',
            color: '#fff',
            fontSize: '14px',
            marginBottom: '10px',
        },
        testimonialContent: {
            fontSize: '15px',
            color: darkMode ? '#bbb' : '#555',
            fontWeight: 400,
            marginBottom: '20px',
        },
        readMore: {
            display: 'inline-block',
            fontSize: '14px',
            color: darkMode ? 'white' : 'black',
            textDecoration: 'none',
        },
        testimonialHeading: {
            fontWeight: 600,
            fontSize: 20,
            color: darkMode ? 'white' : 'black',
            marginBottom: 0,
        },
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 250,
        slidesToShow: isLargeScreen ? 3.5 : isMediumScreen ? 2 : 1,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: false,
        autoplay: true,
    };

    const testimonials = [
        {
            badge: 'Sheshadri Sheshadri', badgeBg: '#B000DC1C', badgeColor: '#B000DC',
            content: '"CodeStep Technology Express built our great-looking, functional website. Highly recommend contacting Gus for custom web solutions. Their expertise and determination are exceptional!",'
        },
        {
            badge: 'Savita Hanva', badgeBg: '#DC42001C', badgeColor: '#DC1A00',
            content: 'Codestep Technologies developed our intuitive, feature-rich fitness app. Knowledgeable team, excellent communication, and impressive attention to detail. We look forward to future collaborations,'
        },
        {
            badge: 'Ravi Sharma', badgeBg: '#00DC8D1C', badgeColor: '#00DC72',
            content: 'CodeStep Technologies delivered top-quality, customized software solutions. Their skilled, dedicated team exceeded our expectations by thoroughly understanding our business needs. Highly recommend!,'
        },
        {
            badge: 'Priya Gupta', badgeBg: '#0058DC17', badgeColor: '#0058DC',
            content: 'CodeStep Technologies transformed our website with exceptional design and development. The process was smooth, and the team was responsive and professional. Our new site looks amazing,'
        },
    ];

    return (
        <div style={styles.container}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Heading title={'Clients Feedbacks'} />
                <p style={{ ...styles.description }}>
                    Trusted by Leading Brands and Businesses.
                </p>
            </div>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index}>
                        <div style={styles.testimonialItem}>
                            <div style={{ ...styles.badge, backgroundColor: testimonial.badgeBg, color: testimonial.badgeColor }}>{testimonial.badge}</div>
                            <h3 style={styles.testimonialHeading}>Kind Words</h3>
                            <p style={styles.testimonialContent}>{testimonial.content}</p>
                            {/* <a href="#" style={styles.readMore}>Read more ➔</a> */}
                        </div>
                    </div>
                ))}
            </Slider>
            
            <center>
            <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> 
            <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="2447703"></div>
            </center>

        </div>
    );
};

export default Testimonials;