import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

const WhyChooseUs = () => {
    const isTabletOrMobile = useMediaQuery('(max-width: 1023px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            position: 'relative',
            textAlign: 'center',
            padding: isTabletOrMobile ? '50px 20px' : '10% 15%',
            color: '#fff',
            overflowX: 'hidden', // Add this line
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/bg-image.jpeg") center/cover no-repeat',
            zIndex: 1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: 2,
        },
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: isTabletOrMobile ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
            gap: '20px',
            justifyItems: 'center',
        },
        gridItem: (index) => ({
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '10px',
            padding: '20px',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            webkitBackdropFilter: 'blur(10px)',
            width: '80%',
            opacity: 0,
            transform: isVisible ? 'translateX(0)' : (index % 2 === 0 ? 'translateX(-100%)' : 'translateX(100%)'),
            transition: 'transform 1s ease-out, opacity 1s ease-out',
        }),
        itemVisible: {
            opacity: 1,
            transform: 'translateX(0)',
        },
        number: {
            fontSize: '32px',
            fontWeight: 'bold',
        },
        label: {
            fontSize: '16px',
            marginTop: '10px',
        },
    };

    return (
        <div ref={sectionRef} style={styles.container}>
            <div style={styles.background}></div>
            <div style={styles.overlay}></div>
            <div style={{ zIndex: 4, position: 'relative' }}>
                <h2 style={{ fontWeight: 600 }}>Why Choose Us</h2>
                <div style={styles.gridContainer}>
                    {[
                        { number: '10+', label: 'Websites Developed' },
                        { number: '35+', label: 'Projects Completed' },
                        { number: '5+', label: 'Apps Developed' },
                        { number: '30+', label: 'Happy Clients' },
                        { number: '8+', label: 'Technical & Designer Team' },
                        { number: '3+', label: 'Years of Experience' },
                    ].map((item, index) => (
                        <div key={index} style={{ ...styles.gridItem(index), ...(isVisible && styles.itemVisible) }}>
                            <div style={styles.number}>{item.number}</div>
                            <div style={styles.label}>{item.label}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
