import React, { useContext, useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';
import { useNavigate } from 'react-router-dom';

const CTA = () => {
    const navigate = useNavigate();
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            position: 'relative',
            overflow: 'hidden',
        },
        visible: {
            opacity: 1,
            transform: 'translateY(0)',
        },
        hidden: {
            opacity: 0,
            transform: 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: isSmallScreen ? '20px' : '0',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(-50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        image: {
            width: '90%',
            height: 'auto',
            borderRadius: 15,
        },
        contentContainer: {
            flex: 1,
            paddingLeft: isSmallScreen ? '0' : '20px',
            textAlign: isSmallScreen ? 'center' : 'left',
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        heading: {
            fontSize: '24px',
            width: isMediumScreen ? '' : isSmallScreen ? '' : '70%',
            fontWeight: 600,
            marginBottom: '20px',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            width: isMediumScreen ? '' : isSmallScreen ? '' : '85%',
            marginBottom: '20px',
            lineHeight: '1.6',
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px 30px',
            borderRadius: '5px',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
            cursor: 'pointer',
        },
    };

    return (
        <div ref={sectionRef} style={{ ...styles.container }}>
            <div style={styles.imageContainer}>
                <img src="/images/cta-image.svg" alt="CTA Illustration" style={styles.image} />
            </div>
            <div style={styles.contentContainer}>
                <h2 style={styles.heading}>OUR PROMISES</h2>
                <p style={styles.description}>
                    At CodeStep Technologies, we promise unwavering commitment to excellence, innovation, and customer satisfaction. We guarantee to deliver tailored solutions that exceed expectations, leveraging cutting-edge technology and industry best practices. Our dedicated team ensures transparent communication, timely delivery, and continuous support to empower your business growth and success.
                </p>
                <p style={styles.description}>
                    Trust us to be your reliable partner in achieving digital transformation with integrity and quality at every step.
                </p>
                <a onClick={() => { navigate('/about'); window.scrollTo(0, 0) }} style={styles.button}>Read more</a>
            </div>
        </div>
    );
};

export default CTA;