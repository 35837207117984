import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import ContactHeroSection from "../Components/ContactHeroSection";
import ConnectUs from "../Components/ConnectUs";
import ContactForm from "../Components/ContactForm";
import { useEffect, useState } from "react";
import Loader from "../Components/Loader";

export default function Contact() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500);
    }, [])

    return (
        <>
            {
                loading ?
                    <>
                        <Loader />
                    </>
                    :
                    <>
                        <Header />
                        <ContactHeroSection />
                        <ConnectUs />
                        <ContactForm />
                        <Newsletter />
                        <Footer />
                    </>
            }
        </>
    )
}