import React, { useContext, useRef, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';
import emailjs from 'emailjs-com';

const Newsletter = () => {
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 12% 8%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            opacity: 0,
            transform: 'translateY(20px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
        },
        visible: {
            opacity: 1,
            transform: 'translateY(0)',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            textAlign: isSmallScreen ? 'center' : 'left',
            marginBottom: '10px',
            animation: 'slideInLeft 0.8s forwards',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#bbb' : '#555',
            textAlign: isSmallScreen ? 'center' : 'left',
            width: isMediumScreen || isSmallScreen ? '' : '45%',
            margin: isSmallScreen ? '0 auto 40px auto' : '0 0 40px 0',
            animation: 'slideInLeft 0.8s forwards',
        },
        newsletterContainer: {
            backgroundColor: darkMode ? '#333' : '#1B1B1B',
            padding: isMediumScreen || isSmallScreen ? '60px 20px' : '3% 4%',
            borderRadius: '13px',
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            animation: 'fadeIn 0.8s forwards',
        },
        newsletterText: {
            color: '#fff',
            textAlign: isSmallScreen ? 'center' : 'left',
            marginBottom: isSmallScreen ? '20px' : '0',
        },
        inputContainer: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            width: isSmallScreen || isMediumScreen ? '100%' : '40%',
            position: 'relative',
        },
        input: {
            padding: '15px 20px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            flex: 1,
            marginRight: isSmallScreen ? '0' : '10px',
            marginBottom: isSmallScreen ? '10px' : '0',
            width: '100%',
            boxSizing: 'border-box',
        },
        buttonContainer: {
            position: isSmallScreen ? 'relative' : 'absolute',
            right: isSmallScreen ? '0' : '15px',
            display: 'flex',
            justifyContent: isSmallScreen ? 'center' : 'flex-end',
            alignItems: 'center',
            height: '100%',
        },
        button: {
            backgroundColor: darkMode ? 'black' : 'black',
            color: darkMode ? 'white' : '#fff',
            padding: '10px 20px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            boxSizing: 'border-box',
        },
        messageBox: {
            padding: '15px',
            borderRadius: '5px',
            marginTop: '20px',
            textAlign: 'center',
            backgroundColor: status ? '#d4edda' : '#f8d7da',
            color: status ? '#155724' : '#721c24',
            border: `1px solid ${status ? '#c3e6cb' : '#f5c6cb'}`
        },
        '@keyframes slideInLeft': {
            '0%': {
                transform: 'translateX(-100%)',
                opacity: 0,
            },
            '100%': {
                transform: 'translateX(0)',
                opacity: 1,
            },
        },
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {
            to_name: 'Codestep Technologies',
            email: formData?.email,
        }
        emailjs.send(
            'service_jc8vopm',
            'template_0c6haok',
            payload,
            '29EF8Z6H3DqrJD3jn'
        ).then((result) => {
            setStatus(true);
            setLoading(false);
        }, (error) => {
            setStatus(false);
            setLoading(false);
        });
    };

    return (
        <div ref={sectionRef} style={{ ...styles.container, ...(isVisible && styles.visible) }}>
            <h2 style={styles.header}>Subscribe to Our Newsletter</h2>
            <p style={styles.description}>
                Stay informed with the latest updates, promotions, and insights from CodeStep Technologies. Enter your email below to subscribe to our newsletter:
            </p>
            <div style={styles.newsletterContainer}>
                <div style={styles.newsletterText}>
                    <h3 style={{ margin: 0, fontWeight: 600, fontSize: 25 }}>Stay in the loop</h3>
                    <p style={{ marginTop: '2%', width: isMediumScreen || isSmallScreen ? '' : '70%', fontWeight: 400, fontSize: 15 }}>Subscribe to receive the latest news and updates. We promise not to spam you!</p>
                </div>
                <div style={styles.inputContainer}>
                    <input type="email" placeholder="Enter email address" style={styles.input} onChange={(e) => setFormData({ ...formData, email: e?.target?.value })} />
                    <div style={styles.buttonContainer}>
                        <button style={styles.button} onClick={handleSubmit}>{loading ? 'Continue...' : 'Continue'}</button>
                    </div>
                </div>
            </div>
            {status !== null && (
                <div style={styles.messageBox}>
                    {status ? 'Thanks for subscribing to us!' : 'Failed to subscribe!'}
                </div>
            )}
        </div>
    );
};

export default Newsletter;