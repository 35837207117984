import React, { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';
import { useNavigate } from 'react-router-dom';

const HeroSection = () => {

    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { darkMode } = useContext(DarkModeContext);
    const headings = ["Transforming Ideas into Digital Excellence", "Innovative Solutions for Modern Businesses", "Delivering Quality and Value"];

    const [currentHeading, setCurrentHeading] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayText((prev) => prev + headings[currentHeading][charIndex]);
            setCharIndex((prev) => prev + 1);
        }, 100);

        if (charIndex === headings[currentHeading].length) {
            clearInterval(interval);
            setTimeout(() => {
                setCurrentHeading((prev) => (prev + 1) % headings.length);
                setDisplayText("");
                setCharIndex(0);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [charIndex, currentHeading, headings]);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            position: 'relative',
            color: darkMode ? '#fff' : '#000',
            overflow: 'hidden',
        },
        content: {
            maxWidth: '1200px',
            width: '100%',
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            alignItems: 'flex-start',
        },
        textContainer: {
            flex: 1,
            width: isDesktop ? '50%' : '100%',
            paddingRight: '20px',
            zIndex: 2,
            marginTop: '3%',
            animation: 'slideInLeft 1s ease-out',
        },
        heading: {
            color: darkMode ? 'white' : 'black',
            fontSize: '20px',
            fontWeight: 500,
        },
        mainHeading: {
            color: darkMode ? '#fff' : '#000',
            fontSize: isDesktop ? '48px' : '35px',
            fontWeight: 600,
            lineHeight: '1.2',
            margin: 0,
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            width: '100%',
            height: isDesktop ? 120 : 150,
            display: 'flex',
            alignItems: 'center',
        },
        description: {
            color: darkMode ? '#bbb' : '#555',
            fontSize: '16px',
            lineHeight: '1.6',
            margin: '20px 0',
        },
        buttonContainer: {
            marginTop: '20px',
        },
        button: {
            backgroundColor: darkMode ? 'white' : 'black',
            color: darkMode ? 'black' : '#fff',
            padding: '15px 30px',
            borderRadius: '5px',
            fontSize: '16px',
            textDecoration: 'none',
            display: 'inline-block',
            cursor: 'pointer',
        },
        imageContainer: {
            flex: 1,
            width: isDesktop ? '50%' : '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: isDesktop ? '0' : '30px',
            zIndex: 2,
            animation: 'slideInRight 1s ease-out',
        },
        image: {
            maxWidth: '100%',
            height: 'auto',
        },
        waveContainer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            overflow: 'hidden',
            lineHeight: 0,
            zIndex: 1,
        },
        waveSvg: {
            width: '100%',
            height: 'auto',
        },
        rectangleImage: {
            position: 'absolute',
            top: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
        },
        triangleImage: {
            position: 'absolute',
            top: '350px',
            left: '5%',
            transform: 'translateX(-50%)',
            zIndex: 1,
        },
        lineImage: {
            position: 'absolute',
            top: '0',
            right: '-20%',
            transform: 'translateX(-50%)',
            zIndex: 1,
        },
        backgroundAnimation: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
            background: `url(${darkMode ? '/images/hero-bg-dark.svg' : '/images/hero-bg-light.svg'}) no-repeat center center/cover`,
            animation: 'moveBackground 20s linear infinite',
        },
    };

    return (
        <div style={styles.container}>
            <style>
                {`
                    @keyframes slideInLeft {
                        0% {
                            transform: translateX(-100%);
                            opacity: 0;
                        }
                        100% {
                            transform: translateX(0);
                            opacity: 1;
                        }
                    }

                    @keyframes slideInRight {
                        0% {
                            transform: translateX(100%);
                            opacity: 0;
                        }
                        100% {
                            transform: translateX(0);
                            opacity: 1;
                        }
                    }

                    @keyframes moveBackground {
                        0% { background-position: 0 0; }
                        100% { background-position: 100% 0; }
                    }
                `}
            </style>
            <div style={styles.backgroundAnimation}></div>
            <div style={styles.content}>
                <div style={styles.textContainer}>
                    <p style={styles.heading}>Software Development Agency</p>
                    <h1 style={styles.mainHeading}>{displayText}</h1>
                    <p style={styles.description}>
                        Expertise in Software Development, Web Development, and Branding
                    </p>
                    <div style={styles.buttonContainer}>
                        <a onClick={() => { navigate('/contact'); window.scrollTo(0, 0) }} style={styles.button}>Get started</a>
                    </div>
                </div>
                <div style={styles.imageContainer}>
                    <img src="/images/hero-image.svg" alt="Illustration" style={styles.image} />
                </div>
            </div>
            <div style={styles.waveContainer}>
                <img src={darkMode ? '/images/wave-dark.svg' : '/images/wave.svg'} style={styles.waveSvg} />
            </div>
            <img src='/images/rectangle.svg' style={styles.rectangleImage} />
            <img src='/images/triangle.svg' style={styles.triangleImage} />
            <img src='/images/line.svg' style={styles.lineImage} />
        </div>
    );
};

export default HeroSection;
