import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';

export default function ServiceEcom() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        E-Commerce Website Development Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in creating robust and user-friendly E-Commerce
                        websites that drive sales, enhance user experience, and streamline online transactions. Our E
                        Commerce solutions are designed to meet the unique needs of businesses looking to establish
                        or expand their online presence.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Custom E-Commerce Solutions",
        "description": "We offer custom E-Commerce website development tailored to your business requirements and goals. Whether you're starting from scratch or upgrading an existing platform, our team ensures a scalable and feature-rich solution."
    },
    {
        "title": "Platform Selection and Integration",
        "description": "Based on your business needs and budget, we recommend and integrate leading E-Commerce platforms such as Shopify, WooCommerce, Magento, or develop custom solutions. This ensures flexibility and scalability to support your business growth."
    },
    {
        "title": "Responsive Design",
        "description": "Our E-Commerce websites are designed to provide a seamless shopping experience across all devices, including desktops, tablets, and smartphones. Responsive design enhances usability and boosts conversion rates by catering to diverse user preferences."
    },
    {
        "title": "Product Management and Inventory Control",
        "description": "We implement robust product management features that allow you to efficiently manage catalogs, inventory, pricing, and promotions. Automated inventory tracking and reporting ensure accurate stock management and timely updates."
    },
    {
        "title": "Secure Payment Gateway Integration",
        "description": "Enable secure online transactions with seamless integration of popular payment gateways such as PayPal, Stripe, Authorize.Net, and more. We prioritize PCI compliance to safeguard customer payment information and build trust."
    },
    {
        "title": "User Experience Optimization",
        "description": "We focus on creating intuitive navigation, clear call-to-actions, and engaging product displays to enhance user experience. User interface (UI) enhancements and usability testing ensure a smooth and enjoyable shopping journey."
    },
    {
        "title": "SEO and Digital Marketing Integration",
        "description": "Increase visibility and attract organic traffic with SEO-friendly E-Commerce websites. We implement SEO best practices, such as optimized product descriptions, metadata, and site structure, to improve search engine rankings."
    },
    {
        "title": "Analytics and Reporting",
        "description": "Gain valuable insights into customer behavior, sales performance, and marketing effectiveness with integrated analytics tools. Performance metrics and data-driven insights empower informed decision-making and continuous improvement."
    },
    {
        "title": "Support and Maintenance",
        "description": "Beyond launch, we provide ongoing support and maintenance services to keep your E-Commerce website secure, updated, and performing optimally. Our support team is available to resolve issues promptly and ensure uninterrupted operation."
    },
    {
        "title": "Client-Centric Approach",
        "description": "We prioritize collaboration and transparent communication throughout the development process. Our goal is to deliver E-Commerce solutions that align with your business objectives and exceed customer expectations."
    }
]
