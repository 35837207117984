import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import Heading from './Heading';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const OurVision = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const visionPoints = [
        {
            heading: 'Innovation',
            description: 'Constantly striving to innovate and adapt to new technologies and industry trends.',
        },
        {
            heading: 'Integrity',
            description: 'Upholding honesty, transparency, and ethical practices in all our interactions.',
        },
        {
            heading: 'Quality',
            description: 'Committing to deliver excellence and superior quality in every solution we provide.',
        },
        {
            heading: 'Trust',
            description: 'Building trust through reliability, consistency, and accountability in our services.',
        },
        {
            heading: 'Focus on Customer',
            description: 'Placing the customer at the center of our operations, understanding their needs, and delivering solutions that exceed expectations.',
        },
        {
            heading: 'Team work',
            description: 'Fostering a collaborative environment where teamwork and mutual respect drive success.'
        },
        {
            heading: 'Sustainable Growth',
            description: 'Pursuing growth that respects environmental and social responsibilities.',
        },
        {
            heading: 'Delivery & Inclusion',
            description: 'Ensuring timely delivery of projects with uncompromised quality and efficiency.',
        },
        {
            heading: 'Inclusion',
            description: 'Embracing diversity and inclusion in our workforce, respecting all perspectives and contributions.',
        },
    ];

    return (
        <div style={styles.container}>
            <Heading title={'Our Vision'} />
            <p style={styles.description}>
                To be a global leader in technology solutions, empowering businesses with
                innovative and sustainable digital transformations.
            </p>
            <h3 style={{ ...styles.heading, fontWeight: 600, fontSize: 28, margin: 0 }}>Core Values</h3>
            <div style={styles.pointsContainer}>
                {visionPoints.map((point, index) => (
                    <div key={index} style={styles.point}>
                        <h3 style={styles.pointHeading}>{point.heading}</h3>
                        <p style={styles.pointDescription}>{point.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurVision;