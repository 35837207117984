import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { DarkModeContext } from './DarkModeContext';
import PhoneIcon from '@mui/icons-material/Phone';
import { IoLocationSharp } from "react-icons/io5";
import { primaryDarkColor } from '../../constant';

const ConnectUs = () => {

    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '0 20px',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
            textAlign: 'center',
        },
        section: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px 20px',
            flex: 1,
        },
        separator: {
            width: '1px',
            height: '50px',
            backgroundColor: darkMode ? '#555' : '#e0e0e0',
            display: isSmallScreen ? 'none' : 'block',
        },
        icon: {
            marginBottom: '10px',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        socialIcons: {
            display: 'flex',
            gap: '10px',
        },
        socialIcon: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: darkMode ? '#555' : '#f9f9f9',
        },
        text: {
            fontSize: '14px',
            color: darkMode ? '#ccc' : '#555',
            fontWeight: 500,
        },
        contactText: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            fontSize: 14,
            flexDirection: isSmallScreen || isMediumScreen ? 'column' : 'row',
        },
        contactNumber: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row',
            marginTop: isSmallScreen || isMediumScreen ? '10px' : '0',
        },
        separator: {
            height: '20px',
            width: '2px',
            backgroundColor: '#ccc',
            margin: '0 10px',
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.section}>
                <p style={styles.text}>Follow us</p>
                <div style={styles.socialIcons}>
                    <a target='_blank' href="https://www.instagram.com/codestep_technologies/" style={styles.socialIcon}>
                        <AiFillInstagram style={{ color: darkMode ? 'white' : 'black' }} />
                    </a>
                    <a target='_blank' href="https://www.linkedin.com/company/codestep-technologies/" style={styles.socialIcon}>
                        <FaLinkedin style={{ color: darkMode ? 'white' : 'black' }} />
                    </a>
                    <a target='_blank' href="https://www.facebook.com/profile.php?id=61561375462349" style={styles.socialIcon}>
                        <FaFacebook style={{ color: darkMode ? 'white' : 'black' }} />
                    </a>
                </div>
            </div>
            <div style={styles.separator}></div>
            <div style={styles.section}>
                <div style={styles.contactText}>
                    {/* <PhoneIcon style={styles.icon} /> */}
                    <div style={styles.contactNumber}>
                        <PhoneIcon style={styles.icon} />
                        <span>+91 6362153519</span>
                        <div style={styles.separator}></div>
                        <PhoneIcon style={styles.icon} />
                        <span>+91 9740503541</span>
                    </div>
                </div>
            </div>
            <div style={styles.separator}></div>
            <div style={styles.section}>
                <div style={styles.contactText}>
                    <IoLocationSharp style={styles.icon} />
                    <span>
                        13, 1st Floor, 3rd Cross, Sri Balaji Layout, Near Corporation Bank,
                        Sarjapura-Attibele Road, Sarjapura, Bangalore, Karnataka
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ConnectUs;
