import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from '../Components/DarkModeContext';
import { primaryDarkColor } from '../../constant';
import Header from '../Components/Header';
import Newsletter from '../Components/Newsletter';
import Footer from '../Components/Footer';
import Gallery from '../Components/Gallery';

export default function ServiceProductDesign() {

    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');
    const { darkMode } = useContext(DarkModeContext);

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30px 20px' : '3% 6%',
            background: darkMode ? primaryDarkColor : '#EFEFEF',
            color: darkMode ? '#fff' : '#000',
        },
        bannerContainer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isSmallScreen || isMediumScreen ? '30% 2%' : '17% 6%',
        },
        background: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'url("/images/web-banner.jpg") center/cover no-repeat',
            zIndex: -1,
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#000000c0',
            zIndex: -1,
        },
        textContainer: {
            flex: 1,
            textAlign: 'left',
            padding: isSmallScreen ? '20px 0' : '0 20px',
        },
        heading: {
            fontSize: isSmallScreen ? '36px' : '48px',
            fontWeight: '600',
            marginBottom: '20px',
        },
        subHeading: {
            fontSize: '18px',
            color: darkMode ? '#bbb' : '#555',
        },
        imageContainer: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '300px' : '500px',
        },
        featuresContainer: {
            padding: isSmallScreen || isMediumScreen ? '50px 20px' : '3% 10% 6%',
            textAlign: 'center',
            backgroundColor: darkMode ? primaryDarkColor : 'white',
        },
        description: {
            fontSize: '16px',
            color: darkMode ? '#ccc' : '#555',
            width: isSmallScreen || isMediumScreen ? '' : '100%',
            marginBottom: '20px',
        },
        heading: {
            fontSize: '36px',
            fontWeight: '600',
            marginBottom: '20px'
        },
        pointsContainer: {
            display: 'grid',
            gridTemplateColumns: isSmallScreen ? '1fr' : isMediumScreen ? '1fr 1fr' : '1fr 1fr 1fr',
            gap: '20px',
            marginTop: '30px',
        },
        point: {
            padding: '20px',
            borderRadius: 0,
            textAlign: 'left',
        },
        pointHeading: {
            fontSize: '20px',
            fontWeight: '500',
            marginBottom: '10px',
            color: darkMode ? 'white' : 'black'
        },
        pointDescription: {
            fontSize: '16px',
            color: darkMode ? 'gainsboro' : '#555'
        },
    };

    const images = [
        { src: 'product-design-1.webp', alt: 'Image 1' },
        { src: 'product-design-2.webp', alt: 'Image 2' },
        { src: 'product-design-3.webp', alt: 'Image 3' },
        { src: 'product-design-4.webp', alt: 'Image 4' },
    ];

    return (
        <>
            <Header />
            <div style={styles.container}>
                <div style={styles.textContainer}>
                    <h2 style={styles.heading}>
                        Product Design Services
                    </h2>
                    <p style={styles.subHeading}>
                        At CodeStep Technologies, we specialize in comprehensive product design services that
                        encompass the entire lifecycle of product development. Our approach combines creativity,
                        technical expertise, and user-centric design principles to create innovative and market-ready
                        products.
                    </p>
                </div>
                <div style={styles.imageContainer}>
                </div>
            </div>
            <div style={styles.featuresContainer}>
                <div style={styles.pointsContainer}>
                    {services.map((point, index) => (
                        <div key={index} style={styles.point}>
                            <h3 style={styles.pointHeading}>{point.title}</h3>
                            <p style={styles.pointDescription}>{point.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Gallery images={images} />
            <Newsletter />
            <Footer />
        </>
    );
};

const services = [
    {
        "title": "Conceptualization and Ideation",
        "description": "We start by collaborating closely with clients to understand their vision, goals, and target audience. Through brainstorming sessions and market research, we generate creative concepts and ideas that align with business objectives."
    },
    {
        "title": "Prototyping and Validation",
        "description": "Our product design process includes rapid prototyping and iterative testing to validate concepts and gather user feedback early in the development cycle. This iterative approach allows us to refine features, functionalities, and user interfaces for optimal usability and market fit."
    },
    {
        "title": "User Experience (UX) Design",
        "description": "We prioritize user experience design to ensure intuitive and engaging interactions with the product. Through user research, wireframing, and usability testing, we design seamless user journeys that enhance satisfaction and retention."
    },
    {
        "title": "Industrial Design",
        "description": "For physical products, we offer industrial design services that focus on aesthetics, ergonomics, and manufacturability. Our designers create sleek and functional product designs that resonate with users while meeting technical and production requirements."
    },
    {
        "title": "Digital Prototyping and Visualization",
        "description": "Using advanced 3D modeling and rendering software, we create digital prototypes that visualize product concepts with high fidelity. This enables stakeholders to review and refine designs before moving into production, saving time and resources."
    },
    {
        "title": "Design for Manufacturing (DFM)",
        "description": "We collaborate closely with manufacturing partners to ensure designs are optimized for production efficiency and cost-effectiveness. Our DFM expertise minimizes potential manufacturing challenges and accelerates time-to-market."
    },
    {
        "title": "Brand Integration and Packaging Design",
        "description": "We integrate brand identity elements into product designs to create cohesive brand experiences. Our team also provides packaging design services that protect products, convey brand messaging, and enhance shelf appeal."
    },
    {
        "title": "Quality Assurance and Testing",
        "description": "Rigorous testing and quality assurance protocols are implemented throughout the product development process. This includes functional testing, usability testing, and compliance testing to ensure products meet industry standards and user expectations."
    },
    {
        "title": "Launch and Market Support",
        "description": "We support product launches with strategic marketing collateral, digital assets, and go-to-market strategies. Our goal is to create a compelling product narrative that resonates with target audiences and drives adoption."
    },
    {
        "title": "Continuous Improvement and Iteration",
        "description": "Post-launch, we monitor product performance and gather user feedback to iterate and enhance features based on real-world usage. This iterative approach ensures products evolve to meet changing market demands and user needs."
    }
]
