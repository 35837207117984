import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { DarkModeContext } from './DarkModeContext';
import { primaryDarkColor } from '../../constant';

const AddonsServices = () => {
    const { darkMode } = useContext(DarkModeContext);
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
    const isMediumScreen = useMediaQuery('(min-width: 600px) and (max-width: 1023px)');

    const styles = {
        container: {
            padding: isSmallScreen || isMediumScreen ? '20px' : '0.5% 6% 4%',
            backgroundColor: darkMode ? primaryDarkColor : '#fff',
            color: darkMode ? '#fff' : '#000',
        },
        header: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '20px',
            textAlign: 'left',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
            textAlign: 'left',
            marginTop: '20px',
        },
        tableHeader: {
            backgroundColor: darkMode ? '#282828' : '#f8f8f8',
            fontWeight: 600,
            padding: '10px',
            borderBottom: '1px solid #ddd',
        },
        tableRow: {
            borderBottom: '1px solid #ddd',
        },
        tableCell: {
            padding: '20px 10px',
        },
        serviceName: {
            fontWeight: 500,
            fontSize: 14,
        },
        servicePrice: {
            fontWeight: 500,
        },
    };

    const addOnServices = [
        { name: 'Urgent Delivery (1 Day)', price: 'Rs. 499/-' },
        { name: 'Printable Files (AI, EPS, PDF, CDR)', price: 'Rs. 299/-' },
        { name: 'Business Card Design', price: 'Rs. 499/-' },
        { name: 'Letterhead Design', price: 'Rs. 499/-' },
        { name: 'Envelope Design', price: 'Rs. 499/-' },
        { name: 'Banner Design', price: 'Rs. 999/-' },
        { name: 'Social Media Post Design', price: 'Rs. 599/-' },
        { name: 'Flyer & Brochure Design (per page)', price: 'Rs. 599/-' },
        { name: 'Label Design', price: 'Rs. 2999/-' },
        { name: 'Box Design & Packaging Design', price: 'Rs. 2999/-' },
    ];

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Extra Services (Add-ons)</h2>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.tableHeader}>Service</th>
                        <th style={styles.tableHeader}>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {addOnServices.map((service, index) => (
                        <tr key={index} style={styles.tableRow}>
                            <td style={{ ...styles.tableCell, ...styles.serviceName }}>{service.name}</td>
                            <td style={{ ...styles.tableCell, ...styles.servicePrice }}>{service.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AddonsServices;